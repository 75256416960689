import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { Dropdown } from "semantic-ui-react";
import Side_bar from "./Side_bar";
import "semantic-ui-css/semantic.min.css";
import { widget } from "../core/lib/chart/charting_library/charting_library.min";
import { useTranslation } from "react-i18next";
import Widget from "./widget";
import { Button } from "@material-ui/core";
import { Bars } from "react-loader-spinner";
import Moment from "moment";
import ICON from "../assets/deposit-imp.png";
import axios from "axios";

const Dashboard = () => {
  const navigate = useNavigate();

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const [siteLoader, setSiteLoader] = useState(false);

  const [kycStatus, setkycStatus, kycStatusref] = useState();
  const [kycDetails, setkycDetails, kycDetailsref] = useState();
  const [fromCurrency, setfromCurrencyRef, fromCurrencyRef] = useState([]);
  const [toCurrency, setToCurrencyRef, toCurrencyRef] = useState([]);

  const [type, settype] = useState();

  useEffect(() => {
    var Type = localStorage.getItem("registe_type");
    settype(Type);
  }, [0]);

  const getKYCstatus = async () => {
    var data = {
      apiUrl: apiService.getKYCStatus,
    };
    var getKYC = await getMethod(data);

    if (getKYC.status == true) {
      setkycStatus(getKYC.Message.kycstatus);
    } else {
      setkycStatus(0);
    }
  };

  const [email, setemail, emailref] = useState("");
  const get_user_detais = async () => {
    var data = {
      apiUrl: apiService.getUserDetails,
    };
    var resp = await getMethod(data);

    localStorage.setItem("registe_type", resp.data.registerType);
    get_kyc_details(resp.data.registerType);
    setemail(resp.email);
  };

  const getCurrencyList = async () => {
    try {
      var data = {
        apiUrl: apiService.getCurrencyList,
      };
      setSiteLoader(true);
      var resp = await getMethod(data);
      setSiteLoader(false);
      const transformedFromData = [];
      resp.currency.forEach((item) => {
        transformedFromData.push({
          value: item.currencyName,
          key: item._id,
          text: item.currencySymbol,
          image: {
            avatar: true,
            src: item.Currency_image,
          },
        });
      });

      const transformedToData = [];
      resp.fiat.forEach((item) => {
        transformedToData.push({
          value: item.currencyName,
          key: item._id,
          text: item.currencySymbol,
          image: {
            avatar: true,
            src: item.Currency_image,
          },
        });
      });

      setfromCurrencyRef(transformedFromData);
      setToCurrencyRef(transformedToData);
    } catch (err) {}
  };
  const { t } = useTranslation();

  const [selectedFromCurr, setSelectedFromCurr] = useState("");
  const [selectedTOCurr, setSelectedTOCurr] = useState("");
  const [walletAddress, setwalletAddress] = useState();

  const [loginCheck, setloginCheck] = useState(false);
  useEffect(() => {
    let userToken = localStorage.getItem("user_token");
    getCurrencyList();
    if (userToken) {
      setloginCheck(true);
      get_user_detais();
      // var Type = localStorage.getItem("registe_type");
      // get_kyc_details(Type);
      getKYCstatus();
    } else {
      setloginCheck(false);
    }
  }, []);

  const handleFromCurrencyChoose = async (e, { value }) => {
    setSelectedFromCurr(value);
    const selected = fromCurrency.find((item) => item.value === value);
    var currncy_name =
      selected.text === "LINK" ||
      selected.text === "MATIC" ||
      selected.text === "USDC"
        ? "BNB"
        : selected.text;
    var currncy_key =
      selected.text === "LINK" ||
      selected.text === "MATIC" ||
      selected.text === "USDC"
        ? "66f1115e91642fcd1c976072"
        : selected.key;
    if (loginCheck == true) {
      var obj = {
        currencySymbol: currncy_name,
        currId: currncy_key,
        network: "",
      };

      var data = {
        apiUrl: apiService.generateAddress,
        payload: obj,
      };

      var resp = await postMethod(data);
      if (resp.status) {
        setwalletAddress(resp.data.address);
      }
    }
  };
  const handleToCurrencyChoose = (e, { value }) => {
    setSelectedTOCurr(value);
  };

  var get_kyc_details = async (type) => {
    var obj = {
      type: type,
    };
    var data = {
      apiUrl: apiService.kyc_details,
      payload: obj,
    };
    var resp = await postMethod(data);

    if (resp.status) {
      var obj = {
        firstName: resp.data.firstName,
        lastName: resp.data.lastName,
        email: emailref.current,
        mobileNumber: resp.data.mobileNumber,
        dob: resp.data.dob,
        // address: {
        addressLine1: resp.data.addressLine1,
        addressLine2: resp.data.addressLine2,
        city: resp.data.city,
        state: resp.data.state,
        postCode: resp.data.postCode,
        countryCode: resp.data.countryCode,
        // },
      };
      // var obj = {
      //   firstName: 'Satoshi',
      //   lastName: 'Nakamoto',
      //   email: emailref.current,
      //   mobileNumber: '+15417543010',
      //   dob: '1994-08-26',
      //   address: {
      //     addressLine1: '170 Pine St',
      //     addressLine2: 'San Francisco',
      //     city: 'San Francisco',
      //     state: 'CA',
      //     postCode: '94111',
      //     countryCode: 'US',
      //   },
      // };
      setkycDetails(obj);
    }
  };

  return (
    <>
      <section>
        <Header />
      </section>

      {siteLoader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#33c2b9"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <main className="dashboard_main assets_main">
          <div className="container-fluid">
            <div className="row vh-100">
              {loginCheck == true ? (
                <div className="col-lg-2 col-md-0 p-0">
                  <Side_bar />
                </div>
              ) : (
                ""
              )}

              <div className="col-lg-10 col-md-12">
                {loginCheck == false ? (
                  <div className="pad-l-r">
                    <section className="asset_section pad-r-none pad-l-none">
                      <div className="buy_head p-0">
                        <div className="Buycrypto_title">{t("buycrpto")}</div>
                        <div
                          class="nav nav-tabs quick__nav-tabs"
                          id="nav-tab"
                          role="tablist"
                        >
                          <button
                            class="nav-link active"
                            id="nav-buy-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-buy"
                            type="button"
                            role="tab"
                            aria-controls="nav-buy"
                            aria-selected="true"
                          >
                            {t("quick-buy")}
                          </button>
                          {/* <button
                              class="nav-link "
                              id="fiat-profile-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#fiat-profile"
                              type="button"
                              role="tab"
                              aria-controls="fiat-profile"
                              aria-selected="false"
                            >
                              {t("fiat-deposits")}
                            </button> */}
                        </div>
                      </div>
                      <div class="tab-content" id="nav-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="nav-buy"
                          role="tabpanel"
                          aria-labelledby="nav-buy-tab"
                          tabindex="0"
                        >
                          <div className="row justify-content-center">
                            <div className="col-lg-6 Buy_sell">
                              <div className="swap_lft_main">
                                <div
                                  class="nav nav-tabs"
                                  id="nav-tab"
                                  role="tablist"
                                >
                                  <button
                                    class="nav-link active"
                                    id="nav-home-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-home"
                                    type="button"
                                    role="tab"
                                    aria-controls="nav-home"
                                    aria-selected="true"
                                  >
                                    {t("buy")}
                                  </button>
                                  {/* <button
                                      class="nav-link "
                                      id="nav-profile-tab"
                                      data-bs-toggle="tab"
                                      data-bs-target="#nav-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="nav-profile"
                                      aria-selected="false"
                                    >
                                      {t("sell")}
                                    </button> */}
                                </div>

                                <div class="tab-content" id="nav-tabContent">
                                  <div
                                    class="tab-pane fade show active"
                                    id="nav-home"
                                    role="tabpanel"
                                    aria-labelledby="nav-home-tab"
                                    tabindex="0"
                                  >
                                    <>
                                      <div className="form_div ">
                                        <Dropdown
                                          placeholder={t("select-coin")}
                                          fluid
                                          selection
                                          className="dep-drops"
                                          options={fromCurrencyRef.current}
                                          onChange={handleFromCurrencyChoose}
                                        />
                                        {/* <div className="swap_lft_top"> */}
                                        {/* <div className="foot_frst">
                                            <span className="bals_divs_insubhead">
                                              {t("pay")}
                                            </span>
                                            <input
                                              type="number"
                                              min="0"
                                              placeholder="0.00"
                                              // value={fromAmount}
                                              // onChange={(e) =>
                                              //   setAmount(e.target.value, "fromAmount")
                                              // }
                                              className="swap_in_val"
                                            />
                                          </div> */}
                                        {/* <div className="swap_chng_frst">
                                            <div className=" Swapcard swap_drop_all">
                                              <Dropdown
                                                placeholder="Select Coin"
                                                fluid
                                                selection
                                                // options={fromCurrencyRef.current}
                                              />
                                            </div>
                                          </div> */}
                                      </div>

                                      {/* <div className="swap-footers">
                                          <div className="swap-footL">
                                            {" "}
                                            <span>Balance</span> : 0.00 INR
                                          </div>
                                          <div className="swap-footL"></div>
                                        </div> */}

                                      <div className="swap_mid">
                                        <img
                                          src={require("../assets/swaparrow.png")}
                                          width="24px"
                                          className="d-block mx-auto"
                                        />
                                      </div>
                                      <div className="form_div ">
                                        <Dropdown
                                          placeholder={t("select-coin")}
                                          fluid
                                          selection
                                          className="dep-drops"
                                          options={toCurrencyRef.current}
                                          onChange={handleToCurrencyChoose}
                                          // onChange={(e)=>handleDropdownChange("tocurrency",e.target.value)}
                                        />
                                      </div>
                                      {/* </div> */}

                                      {/* <div className="swap-footers">
                                          <div className="swap-footL">
                                            {" "}
                                            <span>{t("price")}</span>
                                          </div>
                                          <div className="swap-footL">
                                            {" "}
                                            1 BTC = 0.00 INR{" "}
                                          </div>
                                        </div> */}

                                      {loginCheck == true ? (
                                        <Widget
                                          fromCurrency={selectedFromCurr}
                                          toCurrency={selectedTOCurr}
                                          walletAddress={walletAddress}
                                          userdata={kycDetailsref.current}
                                          // userdata={
                                          //   {
                                          //   "firstName" : 'Satoshi',
                                          //   "lastName": 'Nakamoto',
                                          //   "email": 'satoshi.nakamoto@transak.com',
                                          //   "mobileNumber": '+15417543010',
                                          //   "dob": '1994-08-26',
                                          //   "address": {
                                          //     "addressLine1": '170 Pine St',
                                          //     "addressLine2": 'San Francisco',
                                          //     "city": 'San Francisco',
                                          //     "state": 'CA',
                                          //     "postCode": '94111',
                                          //     "countryCode": 'US',
                                          //   },
                                          // }
                                          // }
                                        />
                                      ) : (
                                        <div className="buywith_cards">
                                          <div>
                                            <Link
                                              to="/login"
                                              className="text-white log-button"
                                            >
                                              {t("login-to-continue")}
                                            </Link>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  </div>
                                  <div
                                    class="tab-pane fade"
                                    id="nav-profile"
                                    role="tabpanel"
                                    aria-labelledby="nav-profile-tab"
                                    tabindex="0"
                                  >
                                    <>
                                      <div className="swap_lft_top">
                                        <div className="foot_frst">
                                          <span className="bals_divs_insubhead">
                                            {t("sell")}
                                          </span>
                                          <input
                                            type="number"
                                            min="0"
                                            placeholder="0.00"
                                            // value={fromAmount}
                                            // onChange={(e) =>
                                            //   setAmount(e.target.value, "fromAmount")
                                            // }
                                            className="swap_in_val dark-text-white"
                                          />
                                        </div>
                                        <div className="swap_chng_frst">
                                          <div className=" Swapcard swap_drop_all">
                                            <Dropdown
                                              placeholder={t("select-coin")}
                                              fluid
                                              selection
                                              // options={fromCurrencyRef.current}
                                              // onChange={(o) =>
                                              //   onSelect(o, "fromTab")
                                              // }
                                              // onChange={handleOnChange_from}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="swap-footers">
                                        <div className="swap-footL">
                                          {" "}
                                          <span>Balance</span> : 0.00 INR
                                        </div>
                                        <div className="swap-footL"></div>
                                      </div>

                                      <div className="swap_mid">
                                        <img
                                          src={require("../assets/swaparrow.png")}
                                          width="24px"
                                          className="d-block mx-auto"
                                        />
                                      </div>

                                      <div className="swap_lft_top">
                                        <div className="foot_frst">
                                          <span className="bals_divs_insubhead">
                                            {t("receive")}
                                          </span>
                                          <input
                                            type="number"
                                            min="0"
                                            placeholder="0.00"
                                            // value={fromAmount}
                                            // onChange={(e) =>
                                            //   setAmount(e.target.value, "fromAmount")
                                            // }
                                            className="swap_in_val dark-text-white"
                                          />
                                        </div>
                                        <div className="swap_chng_frst">
                                          <div className=" Swapcard swap_drop_all">
                                            <Dropdown
                                              placeholder={t("select-coin")}
                                              fluid
                                              selection
                                              // options={fromCurrencyRef.current}
                                              // onChange={(o) =>
                                              //   onSelect(o, "fromTab")
                                              // }
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="swap-footers">
                                        <div className="swap-footL">
                                          {" "}
                                          <span>{t("price")}</span>
                                        </div>
                                        <div className="swap-footL">
                                          {" "}
                                          1 BTC = 0.00 INR{" "}
                                        </div>
                                      </div>

                                      <div className="buywith_cards">
                                        <button className="sells">
                                          <div>{t("sell")}</div>
                                        </button>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                ) : kycStatusref.current == 1 ? (
                  <div className="pad-l-r">
                    <section className="asset_section pad-r-none pad-l-none">
                      <div className="buy_head p-0">
                        <div className="Buycrypto_title">{t("buycrpto")}</div>
                        <div
                          class="nav nav-tabs quick__nav-tabs"
                          id="nav-tab"
                          role="tablist"
                        >
                          <button
                            class="nav-link active"
                            id="nav-buy-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-buy"
                            type="button"
                            role="tab"
                            aria-controls="nav-buy"
                            aria-selected="true"
                          >
                            {t("quick-buy")}
                          </button>
                          {/* <button
                            class="nav-link "
                            id="fiat-profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#fiat-profile"
                            type="button"
                            role="tab"
                            aria-controls="fiat-profile"
                            aria-selected="false"
                          >
                            {t("fiat-deposits")}
                          </button> */}
                        </div>
                      </div>
                      <div class="tab-content" id="nav-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="nav-buy"
                          role="tabpanel"
                          aria-labelledby="nav-buy-tab"
                          tabindex="0"
                        >
                          <div className="row justify-content-center">
                            <div className="col-lg-6 Buy_sell">
                              <div className="swap_lft_main">
                                <div
                                  class="nav nav-tabs"
                                  id="nav-tab"
                                  role="tablist"
                                >
                                  <button
                                    class="nav-link active"
                                    id="nav-home-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-home"
                                    type="button"
                                    role="tab"
                                    aria-controls="nav-home"
                                    aria-selected="true"
                                  >
                                    {t("buy")}
                                  </button>
                                  {/* <button
                                    class="nav-link "
                                    id="nav-profile-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-profile"
                                    type="button"
                                    role="tab"
                                    aria-controls="nav-profile"
                                    aria-selected="false"
                                  >
                                    {t("sell")}
                                  </button> */}
                                </div>

                                <div class="tab-content" id="nav-tabContent">
                                  <div
                                    class="tab-pane fade show active"
                                    id="nav-home"
                                    role="tabpanel"
                                    aria-labelledby="nav-home-tab"
                                    tabindex="0"
                                  >
                                    <>
                                      <div className="form_div ">
                                        <Dropdown
                                          placeholder={t("select-coin")}
                                          fluid
                                          selection
                                          className="dep-drops"
                                          options={fromCurrencyRef.current}
                                          onChange={handleFromCurrencyChoose}
                                        />
                                        {/* <div className="swap_lft_top"> */}
                                        {/* <div className="foot_frst">
                                          <span className="bals_divs_insubhead">
                                            {t("pay")}
                                          </span>
                                          <input
                                            type="number"
                                            min="0"
                                            placeholder="0.00"
                                            // value={fromAmount}
                                            // onChange={(e) =>
                                            //   setAmount(e.target.value, "fromAmount")
                                            // }
                                            className="swap_in_val"
                                          />
                                        </div> */}
                                        {/* <div className="swap_chng_frst">
                                          <div className=" Swapcard swap_drop_all">
                                            <Dropdown
                                              placeholder="Select Coin"
                                              fluid
                                              selection
                                              // options={fromCurrencyRef.current}
                                            />
                                          </div>
                                        </div> */}
                                      </div>

                                      {/* <div className="swap-footers">
                                        <div className="swap-footL">
                                          {" "}
                                          <span>Balance</span> : 0.00 INR
                                        </div>
                                        <div className="swap-footL"></div>
                                      </div> */}

                                      <div className="swap_mid">
                                        <img
                                          src={require("../assets/swaparrow.png")}
                                          width="24px"
                                          className="d-block mx-auto"
                                        />
                                      </div>
                                      <div className="form_div ">
                                        <Dropdown
                                          placeholder={t("select-coin")}
                                          fluid
                                          selection
                                          className="dep-drops"
                                          options={toCurrencyRef.current}
                                          onChange={handleToCurrencyChoose}
                                          // onChange={(e)=>handleDropdownChange("tocurrency",e.target.value)}
                                        />
                                      </div>
                                      {/* </div> */}

                                      {/* <div className="swap-footers">
                                        <div className="swap-footL">
                                          {" "}
                                          <span>{t("price")}</span>
                                        </div>
                                        <div className="swap-footL">
                                          {" "}
                                          1 BTC = 0.00 INR{" "}
                                        </div>
                                      </div> */}

                                      {loginCheck == false ? (
                                        <div className="buywith_cards">
                                          <div>
                                            <Link
                                              to="/login"
                                              className="text-white log-button"
                                            >
                                              {t("login-to-continue")}
                                            </Link>
                                          </div>
                                        </div>
                                      ) : selectedFromCurr == "" ||
                                        selectedTOCurr == "" ? (
                                        <div className="buywith_cards">
                                          <div
                                            className="text-white log-button"
                                            aria-disabled
                                          >
                                            Buy with Card
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="buywith_cards">
                                          <Widget
                                            fromCurrency={selectedFromCurr}
                                            toCurrency={selectedTOCurr}
                                            walletAddress={walletAddress}
                                            userdata={kycDetailsref.current}
                                          />
                                        </div>
                                      )}
                                    </>
                                  </div>


                                  <div
                                    class="tab-pane fade"
                                    id="nav-profile"
                                    role="tabpanel"
                                    aria-labelledby="nav-profile-tab"
                                    tabindex="0"
                                  >
                                    <>
                                      <div className="swap_lft_top">
                                        <div className="foot_frst">
                                          <span className="bals_divs_insubhead">
                                            {t("sell")}
                                          </span>
                                          <input
                                            type="number"
                                            min="0"
                                            placeholder="0.00"
                                            // value={fromAmount}
                                            // onChange={(e) =>
                                            //   setAmount(e.target.value, "fromAmount")
                                            // }
                                            className="swap_in_val dark-text-white"
                                          />
                                        </div>
                                        <div className="swap_chng_frst">
                                          <div className=" Swapcard swap_drop_all">
                                            <Dropdown
                                              placeholder={t("select-coin")}
                                              fluid
                                              selection
                                              // options={fromCurrencyRef.current}
                                              // onChange={(o) =>
                                              //   onSelect(o, "fromTab")
                                              // }
                                              // onChange={handleOnChange_from}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="swap-footers">
                                        <div className="swap-footL">
                                          {" "}
                                          <span>Balance</span> : 0.00 INR
                                        </div>
                                        <div className="swap-footL"></div>
                                      </div>

                                      <div className="swap_mid">
                                        <img
                                          src={require("../assets/swaparrow.png")}
                                          width="24px"
                                          className="d-block mx-auto"
                                        />
                                      </div>

                                      <div className="swap_lft_top">
                                        <div className="foot_frst">
                                          <span className="bals_divs_insubhead">
                                            {t("receive")}
                                          </span>
                                          <input
                                            type="number"
                                            min="0"
                                            placeholder="0.00"
                                            // value={fromAmount}
                                            // onChange={(e) =>
                                            //   setAmount(e.target.value, "fromAmount")
                                            // }
                                            className="swap_in_val dark-text-white"
                                          />
                                        </div>
                                        <div className="swap_chng_frst">
                                          <div className=" Swapcard swap_drop_all">
                                            <Dropdown
                                              placeholder={t("select-coin")}
                                              fluid
                                              selection
                                              // options={fromCurrencyRef.current}
                                              // onChange={(o) =>
                                              //   onSelect(o, "fromTab")
                                              // }
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="swap-footers">
                                        <div className="swap-footL">
                                          {" "}
                                          <span>{t("price")}</span>
                                        </div>
                                        <div className="swap-footL">
                                          {" "}
                                          1 BTC = 0.00 INR{" "}
                                        </div>
                                      </div>

                                      <div className="buywith_cards">
                                        <button className="sells">
                                          <div>{t("sell")}</div>
                                        </button>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div
                          class="tab-pane fade show "
                          id="fiat-profile"
                          role="tabpanel"
                          aria-labelledby="fiat-profile-tab"
                          tabindex="0"
                        >
                          <div className="row justify-content-center">
                            <div className="col-lg-6 Buy_sell">
                              <div className="swap_lft_main">
                                <>
                                  <div className="swap-footers">
                                    <div className="swap-footL">
                                      {" "}
                                      <h4>{t("fiat-deposit")}</h4>
                                    </div>
                                    <div className="swap-footL">
                                      {" "}
                                      <a>
                                        {t("assets")}{" "}
                                        <i class="ml-3 fa-solid fa-chevron-right"></i>{" "}
                                      </a>
                                    </div>
                                  </div>
                                  <div className="swap_lft_top">
                                    <div className="foot_frst">
                                      <span className="bals_divs_insubhead">
                                        {t("deposit")}
                                      </span>
                                      <input
                                        type="number"
                                        min="0"
                                        placeholder="0.00"
                                        // value={fromAmount}
                                        // onChange={(e) =>
                                        //   setAmount(e.target.value, "fromAmount")
                                        // }
                                        className="swap_in_val dark-text-white"
                                      />
                                    </div>
                                    <div className="swap_chng_frst">
                                      <div className=" Swapcard swap_drop_all">
                                        <Dropdown
                                          placeholder="Select Coin"
                                          fluid
                                          selection
                                          // options={fromCurrencyRef.current}
                                          // onChange={(o) =>
                                          //   onSelect(o, "fromTab")
                                          // }
                                        />
                                      </div>
                                    </div>
                                  </div>
      
                                  <div className="swap-footers">
                                    <div className="swap-footL">
                                      {" "}
                                      <span> {t("min-amount")}</span> : 0.00 INR
                                    </div>
                                    <div className="swap-footL"></div>
                                  </div>
      
                                  <div className="swap_mid">
                                    <img
                                      src={require("../assets/swap.png")}
                                      width="24px"
                                      className="d-block mx-auto"
                                    />
                                  </div>
      
                                  <div className="swap_lft_top">
                                    <div className="">
                                      <span className="bals_divs_insubhead">
                                        {t("payment-method")}
                                      </span>
                                      {/* <input
                                        type="number"
                                        min="0"
                                        placeholder="0.00"
                                        // value={fromAmount}
                                        onChange={(e) =>
                                          setAmount(e.target.value, "fromAmount")
                                        }
                                        className="swap_in_val"
                                      /> */}
                        {/* <div className="clr-junc">
                                        {t("clear-junction")}
                                      </div>
                                    </div> */}
                        {/* <div className="swap_chng_frst">
                                      <div className=" Swapcard swap_drop_all">
                                        <Dropdown
                                          placeholder="Select Coin"
                                          fluid
                                          selection
                                          // options={fromCurrencyRef.current}
                                          // onChange={(o) =>
                                          //   onSelect(o, "fromTab")
                                          // }
                                          onChange={handleOnChange_from}
                                        />
                                      </div>
                                    </div> */}
                        {/* </div>
      
                                  <div className="swap-footers">
                                    <div className="swap-footL">
                                      {" "}
                                      <span>{t("fees")} </span> : 0.00 INR
                                    </div>
                                  </div>
      
                                  <div className="buywith_cards ">
                                    <Link to="/fiat_deposit">
                                      <button className="justify-content-center">
                                        <div>{t("continue")}</div>
                                      </button>
                                    </Link>
                                  </div>
                                </>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </section>
                  </div>
                ) : (
                  <>
                    {/*/TODO: withdraw - KYC Verification required box*/}
                    <div className="row kycrequire">
                      <div className="col-lg-7">
                        <div className="deposit-identity-box mt-5 h-100">
                          <div className="dep-kyc">
                            <div className="dep-kyc-head">
                              <img
                                src={ICON}
                                alt="warn-icon"
                                className="deposit-imp-icon"
                              />
                              {type == "Corporates" ? (
                                <h6>{t("kyb-verification-required")}</h6>
                              ) : (
                                <h6>{t("kyc-verification-required")}</h6>
                              )}
                            </div>
                            {type == "Corporates" ? (
                              <p>
                                {t("kyb-dep-verification-required-content")}
                              </p>
                            ) : (
                              <p>{t("dep-verification-required-content")}</p>
                            )}
                            <div>
                              <img
                                src={require("../assets/deposit-id.webp")}
                                alt="Verify kyc"
                                className="before_kyc_depo withdraw-p-l-24"
                              />
                            </div>
                            <p className="mt-4">{t("please-click-button")}</p>
                            <div className="withdraw-p-l-24">
                              {type == "Corporates" ? (
                                <Link to="/kyb">
                                  <button className="mb-2 dep-kyc-btn w-100">
                                    {t("verify-now")}
                                  </button>
                                </Link>
                              ) : (
                                <Link to="/kyc">
                                  <button className="mb-2 dep-kyc-btn w-100">
                                    {t("verify-now")}
                                  </button>
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </>
                )}
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default Dashboard;
