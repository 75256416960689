import React, { useEffect } from "react";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData2";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Side_bar from "./Side_bar";
import Markettable from "./Markettable";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import Moment from "moment";
import { useTranslation } from "react-i18next";
import { socket } from "../context/socket";

const Market = () => {
  const [currencylistData, setcurrencylistData] = useState([]);
  const [currencyCount, setcurrencyCount] = useState(0);
  const [siteLoader, setSiteLoader] = useState(false);
  const [loginCheck, setloginCheck] = useState(false);
  const [search, setsearch, searchref] = useState("");
  const [pairData, setPairData,pairDataref] = useState([]);

  const [marketPairs, setmarketPairs,marketPairsref] = useState([]);

  const { t } = useTranslation();

  useEffect(() => {
    let userToken = localStorage.getItem("user_token");
    if (userToken) {
      setloginCheck(true);
    } else {
      setloginCheck(false);
    }
    // socket.on("homepagemarketprice", async (response) => {
    //   if (response.data) {
    //   }
    // });
    gethomeCurrency();
    socket.connect();
   
    
  }, [socket]);

  const navtradepage = async (symbol) => {
    window.location.href = `trade/${symbol}_EUR`;
  };

  const gethomeCurrency = async () => {
    var data = {
      apiUrl: apiService.get_market_page,
    };

    setmarketLoader(true);
    var resp = await getMethod(data);
    if (resp.status) {
      setmarketLoader(false);
      setcurrencylistData(resp.data);
      socket.emit("GetTickerPrice_market", "getall");
      socket.on("DashTickerPrice_market", async (response) => {
     // console.log("market tickers-----",response.data);
        let market_response = [];
        if (response.data.length > 0) {
          setPairData(response.data);
          let currencyData = resp.data;
          if(currencyData.length > 0)
          {
            //console.log("call currency",currencyData.length)
            if(pairDataref.current.length > 0)
            {
              for(let pair of pairDataref.current)
              {
                let baseAsset = pair.symbol.split("/")[0];
                let quoteAsset = pair.symbol.split("/")[1];
               // console.log("baseAsset",baseAsset)
                let currency_match = currencyData.filter(resp => resp.currencySymbol == baseAsset);
                //console.log("currency_match",currency_match)
                if(currency_match.length > 0)
                {
                  let obj = {
                    price: pair.last,
                    volume: pair.volume,
                    change_percent: pair.change_pct,
                    currency_image: currency_match[0].Currency_image,
                    pair: pair.symbol,
                    baseAsset: baseAsset,
                    quoteAsset: quoteAsset
                  }
                  market_response.push(obj);
                }
              }
              //console.log("market_response",market_response);
              setmarketPairs(market_response);
            }
          }
          
        }
      });
    } else {
    }
  };

  const handleChange = (e) => {
    try {
      const sanitizedValue = e.target.value.replace(/\s/g, "");
      setsearch(sanitizedValue);
      searchCurrency();
    } catch (error) {}
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const [marketLoader, setmarketLoader] = useState(false);
  const searchCurrency = async () => {
    if (
      searchref.current !== "" &&
      searchref.current !== undefined &&
      searchref.current !== null
    ) {
      const regexPattern = new RegExp(searchref.current, "i");
      if (marketPairsref.current.length > 0) {
        
        const searchWallet = marketPairsref.current.filter((data) =>
          regexPattern.test(data.baseAsset)
        );
        console.log("searchWallet",searchWallet);
        if (searchWallet.length > 0) {
          setmarketPairs(searchWallet);
          console.log("marketPairsref.current",marketPairsref.current);
        } else {
          setmarketPairs([]);
        }
      }
      // const searchWallet = balanceDatas.filter(data => data.currencysymbol.toLowerCase() === searchref.current.toLowerCase());
    } else {
      gethomeCurrency();
    }
  };

  return (
    <>
      <section>
        <Header />
      </section>

      <main className="dashboard_main h-100">
        <div className="container-fluid">
          <div className="row">
            {loginCheck == true ? (
              <div className="col-lg-2 col-md-0 p-0">
                <Side_bar />
              </div>
            ) : (
              ""
            )}
            {siteLoader == true ? (
              <div className="loadercss">
                <Bars
                  height="80"
                  width="80"
                  color="#33c2b9"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              <div
                className={
                  loginCheck == true ? "col-lg-10 col-md-12" : "col-lg-12"
                }
              >
                <div className="pad-l-r">
                  <section className="asset_section pad-r-none pad-l-none">
                    <div className="row">
                      <div className="buy_head">
                        <div className="buy-rewards market-title-mt">
                          <span className="reward-title">{t("market")}</span>
                          <div className="stake-search-container">
                            <input
                              type="text"
                              placeholder={t("search")}
                              className="stake-input"
                              onChange={handleChange}
                              onKeyDown={handleKeyDown}
                              maxLength={10}
                            />
                            <i
                              className="fa-solid fa-magnifying-glass"
                              onClick={() => searchCurrency()}
                            ></i>
                          </div>
                        </div>
                        <ul className="history-lists">
                          <Link className="history-links active">
                            {t("spot")}
                          </Link>
                        </ul>

                        {/* <Markettable/> */}

                        <div className="table-responsive table-cont dash_table_content">
                          <table className="table ">
                            <thead>
                              <tr className="stake-head font-satoshi">
                                <th>{t("name")}</th>
                                {/* <th className="opt-nowrap pad-left-23 px-0">
                                  {t("price")} (USD)
                                </th> */}
                                <th className="opt-nowrap pad-left-23 px-0">
                                  {t("price")}
                                </th>
                                <th className="opt-nowrap pad-left-23 px-0">
                                  {/* {t("price")} (USD) */}
                                  {t("24H-Volume")}
                                </th>
                                <th className="opt-nowrap pad-left-23 px-0">
                                  {/* {t("price")} (USD) */}
                                  {t("24H-Change")}
                                </th>
                                <th className="table_action tab-pad-r-10">
                                  {t("trade")}
                                </th>
                              </tr>
                            </thead>

                            {marketLoader == true ? (
                              <tr>
                                <td colSpan={6} className="text-center py-5">
                                  <div className="empty_data">
                                    <div className="empty_data_img loadercss2">
                                      <Bars
                                        height="80"
                                        width="80"
                                        color="#33c2b9"
                                        ariaLabel="bars-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                      />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ) : (
                              <tbody>
                                {marketPairsref.current &&
                                marketPairsref.current.length > 0 ? (
                                  marketPairsref.current.map((obj, i) => {
                                    const formatter = new Intl.NumberFormat('en-US');
                                    return (
                                      <tr key={i}>
                                        <td className="table-flex">
                                          <img
                                            src={obj.currency_image}
                                            alt=""
                                          />
                                          <div className="table-opt-name">
                                            {/* <h4 className="opt-name font-satoshi font_14">
                                          {obj.currencyName}
                                        </h4> */}
                                            <h3 className="opt-sub font-satoshi font_14">
                                              {obj.pair}
                                            </h3>
                                          </div>
                                        </td>

                                        {/* <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 tab-pad-l-10">
                                          {obj.currentMarketPriceUSD <= 0 ? (
                                            <td className="">
                                              {obj.currencySymbol == "SHIB" ? (
                                                <span className="">
                                                  $
                                                  {parseFloat(
                                                    obj.currentMarketPriceUSD
                                                  ).toFixed(8)}
                                                </span>
                                              ) : (
                                                <span className="">
                                                  ${" "}
                                                  {parseFloat(
                                                    obj.currentMarketPriceUSD
                                                  ).toFixed(2)}
                                                </span>
                                              )}
                                            </td>
                                          ) : (
                                            <td className="">
                                              {obj.currencySymbol == "SHIB" ? (
                                                <span className="">
                                                  $
                                                  {parseFloat(
                                                    obj.currentMarketPriceUSD
                                                  ).toFixed(8)}
                                                </span>
                                              ) : (
                                                <span className="">
                                                  ${" "}
                                                  {parseFloat(
                                                    obj.currentMarketPriceUSD
                                                  ).toFixed(2)}
                                                </span>
                                              )}
                                            </td>
                                          )}
                                        </td> */}
                                        {/* <td className="">
                                          <span className="">
                                            €{obj.currentMarketPriceEUR}
                                          </span>
                                        </td> */}
                                        <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 tab-pad-l-10">
                                        <td className="">
                                          <span className="">
                                            {obj.price}
                                          </span>
                                          </td>
                                        </td>
                                        <td className="opt-term">
                                          <span className="">
                                            {formatter.format(obj.volume)}
                                          </span>
                                        </td>

                                        <td className="opt-term">
                                          {parseFloat(obj.change_percent) > 0 ? (
                                            <span className="text-success">
                                              {obj.change_percent}
                                            </span>
                                          ) : parseFloat(obj.change_percent) < 0 ? (
                                            <span className="text-red">
                                              {obj.change_percent}
                                            </span>
                                          ) : (
                                            <span className="text-muted">
                                              0%
                                            </span>
                                          )}
                                        </td>
                                        <td className="opt-btn-flex table_action pad-left-23">
                                          <div className="deposit_top_button">
                                            <button
                                              className="action_btn "
                                              onClick={() =>
                                                navtradepage(obj.baseAsset)
                                              }
                                            >
                                              {t("trade")}
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td
                                      colSpan={6}
                                      className="text-center py-5"
                                    >
                                      <div className="empty_data">
                                        <div className="empty_data_img">
                                          <img
                                            src={require("../assets/No-data.png")}
                                            width="100px"
                                          />
                                        </div>
                                        <div className="no_records_text">
                                          {t("no-records-found")}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default Market;
