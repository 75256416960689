import React, { useEffect } from "react";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import Header from "./Header";
import Side_bar from "./Side_bar";

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isFocused ? "#33c2b9" : null,
      color: isFocused ? "#fff" : "#000",
      cursor: isDisabled ? "not-allowed" : "pointer",
    };
  },
};

const StakeHisTable = () => {
  const [siteLoader, setSiteLoader] = useState(false);
  const { t } = useTranslation();
  const [totalactive, settotalactive] = useState(0);

  const showErrorToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.error(message);
    setToastId(newToastId);
  };


  const [cryptoHistory, setcryptoHistory,cryptoHistoryref] = useState([]);
  const [totalPage, setTotalpages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  var cryptorecordpage=5
  useEffect(() => {
    cryptohistory(1);
  }, [0]);

  const cryptohistory = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page,
      };
      var data = {
        apiUrl: apiService.getbuycryptTransaction,
        payload: payload,
      };
      setSiteLoader(true);
      var resp = await postMethod(data);
      setSiteLoader(false);
      if (resp.status) {
        setcryptoHistory(resp.data.data);
        setTotalpages(resp.data.total);
      }
    } catch (error) {}
  };

  const recordPerPage = 5;

  const handlePageChange = (event,pageNumber) => {
    cryptohistory(pageNumber);
    setCurrentPage(pageNumber);
  };

  const [toastId, setToastId] = useState(null);
  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };


  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    showSuccessToast(t("Orderid-copied"));
  };


  return (
    <>
       <section>
        <Header />
      </section>


      <main className="dashboard_main h-100">
        <div className="container-fluid">
          <div className="row vh-100">
            <div className="col-lg-2 col-md-0 p-0">
              <Side_bar />
            </div>

            <div className="col-lg-10 col-md-12">
              <div className="pad-l-r">
                <section className="asset_section pad-r-none pad-l-none">
                  <div className="row">
                    <div className="buy_head p-0">
                      <div className="Buycrypto_title">{t("history")}</div>
                      <ul className="history-lists">
                        <Link to="/depositHistory" className="history-links ">
                          {t("deposit")}
                        </Link>
                        <Link to="/withdrawHistory" className="history-links ">
                          {t("withdraw")}
                        </Link>
                        <Link to="/tradeHistory" className="history-links ">
                          {t("trade")}
                        </Link>
                        <Link to="/loginHistory" className="history-links ">
                          {t("login")}
                        </Link>
                        <Link to="/referralHistory" className="history-links ">
                          {t("referral")}
                        </Link>
                        <Link to="/adminrewardTable" className="history-links">
                          {t("reward")}
                        </Link>
                        <Link to="/stakingHistory" className="history-links ">
                          {t("staking")}
                        </Link>
                        <Link to="/swapHistory" className="history-links">
                          {t("swap")}
                        </Link>
                        <Link
                          to="/openorderHistory"
                          className="history-links  "
                        >
                          {t("open-order-history")}
                        </Link>
                        <Link
                          to="/cancelorderHistory"
                          className="history-links "
                        >
                          {t("cancel-order-history")}
                        </Link>
                        <Link
                          to="/buycryptoHistory"
                          className="history-links active"
                        >
                          {t("buy-crypto-history")}
                        </Link>
                       
                      </ul>
                      {/* <AssetListTable /> */}

                      {siteLoader == true ? (
                        <div className="loadercssss">
                          <Bars
                            height="80"
                            width="80"
                            color="#33c2b9"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        <div className="table-responsive table-cont">
                          <table className="table">
                            <thead className="ref_table_head">
                              <tr className="stake-head font-satoshi thead-bor-bottom">
                                <th>   {t("S.No")}</th>
                                <th className="opt-nowrap txt-center pad-left-23">
                                {" "}
                                  {t("Date")}
                                </th>
                                <th className="opt-nowrap txt-center pad-left-23">
                                  {" "}
                                  {t("Cryptocurrency")}
                                </th>
                                <th className="opt-nowrap txt-center pad-left-23">
                                  {" "}
                                  {t("Fiatcurrency")}
                                </th>
                                <th className="opt-nowrap txt-center pad-left-23">
                                  {" "}
                                  {t("CryptoAmount")}
                                </th>
                                <th className="opt-nowrap txt-center pad-left-23">
                                  {" "}
                                  {t("FiatAmount")}
                                </th>
                                <th className="opt-nowrap txt-center pad-left-23">
                                  {" "}
                                  {t("Order-id")}
                                </th>
                                <th className="opt-nowrap txt-center pad-left-23">
                                  {" "}
                                  {t("PaymentOption")}
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {cryptoHistory?.length > 0 ? (
                                cryptoHistory.map((item, i) => {
                                  return (
                                    <tr>
                                      <td className="opt-percent font-satoshi font_14 px-2 pad-top-14px">
                                        {i+1}
                                      </td>
                                      <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                        {moment(item.createdDate).format("lll")}
                                      </td>
                                      {/* <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                        {item.pairName}
                                      </td> */}
                                      <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                        {item.cryptoCurrency}
                                      </td>
                                      <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                        {item.fiatCurrency}
                                      </td>
                                      <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                        {item.fiatAmount}
                                      </td>
                                      <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                        {item.cryptoAmount}
                                      </td>
                                      <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                      {item.orderId.substring(0, 10)}{" "}
                                       ...{" "}
                                       <i
                                         class="fa fa-clone"
                                         aria-hidden="true"
                                         onClick={() =>
                                           copy(item.orderId)
                                         }
                                         style={{ cursor: "pointer",color:"#33c2b9" }}
                                       ></i>
                                      </td>
                                      <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                     {item.paymentOptionId=="credit_debit_card"?"Credit / Debit Card":""}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={10} className="text-center py-5">
                                    <div className="empty_data">
                                      <div className="empty_data_img">
                                        <img
                                          src={require("../assets/No-data.png")}
                                          width="100px"
                                        />
                                      </div>
                                      <div className="no_records_text">
                                        {t("no-records-found")}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {cryptoHistoryref.current && cryptoHistoryref.current.length > 0 ? (
                            <div className="pagination">
                              <Stack spacing={2}>
                                <Pagination
                                  count={Math.ceil(totalPage / recordPerPage)}
                                  page={currentPage}
                                  onChange={handlePageChange}
                                  size="small"
                                  renderItem={(item) => (
                                    <PaginationItem
                                      slots={{
                                        previous: ArrowBackIcon,
                                        next: ArrowForwardIcon,
                                      }}
                                      {...item}
                                      sx={{
                                        "&.Mui-selected": {
                                          backgroundColor: "#33c2b9",
                                          color: "#fff",
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </Stack>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>
        </>
  );
};

export default StakeHisTable;
