import React, { useEffect } from "react";
import useState from "react-usestateref";
import { selectDate, stakeOpt } from "../utils/mockData";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Bitcoin from "../assets/btc.png";
import line from "../assets/straightLine.png";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const StakeOptTable = (data) => {
  const [open, setOpen] = useState(false);
  const [stakeData, setStakeData, stakeDataref] = useState({});
  const [Duration, setDuration] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [stakecurrentDate, setstakeCurrentDate] = useState("");
  const [stakeendDate, setstakeendDate] = useState("");
  const [accuralDate, setaccuralDate] = useState("");
  const [stakeValue, setstakeValue] = useState();
  const [userDailyInterest, setuserDailyInterest] = useState(0);
  const [userTotlaInterest, setuserTotlaInterest] = useState(0);
  const [FlexibleEarn, setFlexibleEarn] = useState(0);
  const [TotalFlexible, setTotalFlexible] = useState(0);
  const [apy, setApy] = useState(0);
  const [errorState, setErrorState] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [buttonStatus, setbuttonStatus] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);

  const [toastId, setToastId] = useState(null);
  const { t } = useTranslation();

  const [loginCheck, setloginCheck] = useState(false);

  useEffect(() => {
    let userToken = localStorage.getItem("user_token");
    if (userToken) {
      setloginCheck(true);
    } else {
      setloginCheck(false);
    }
  }, [0]);
  const [availaleBalance, setavailableBalance, availaleBalanceref] =
    useState(0);
  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const showErrorToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.error(message);
    setToastId(newToastId);
  };

  useEffect(() => {
    const todaydate = new Date();
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    const formattedCurrentDate = todaydate.toLocaleString("en-GB", options);
    setCurrentDate(formattedCurrentDate);
  }, []);

  const getBalance = async () => {
    var obj = { currency_id: stakeDataref.current.currencyId };
    var data = {
      apiUrl: apiService.getBalancestake,
      payload: obj,
    };
    var resp = await postMethod(data);
    setavailableBalance(resp.balance ? resp.balance : 0);
    // getstakeBalance()
  };
  const getstakeBalance = async () => {
    getBalance();
  };
  const caluculateMax = () => {
    if (Duration == "" && stakeData.type === "fixed") {
      setErrorState(true);
      setErrorMsg(t("choose-duration"));
    } else {
      setErrorState(false);
      setstakeValue(availaleBalanceref.current);
      setbuttonStatus(true);
      if (stakeData.type === "fixed") {
        var dailyinterest = availaleBalanceref.current * (+apy / 100 / 365);
        var totalInterest = parseFloat(dailyinterest) * Duration;
        setuserTotlaInterest(totalInterest ? totalInterest : 0);
        setuserDailyInterest(dailyinterest ? dailyinterest : 0);
      } else if (stakeData.type === "flexible") {
        var dailyinterestDate =
          availaleBalanceref.current * (+stakeData.APRinterest / 100 / 365);
        var totalInterestFlex = parseFloat(dailyinterestDate) * 1;
        setFlexibleEarn(dailyinterestDate ? dailyinterestDate : 0);
        setTotalFlexible(totalInterestFlex);
      }
    }
  };
  const handleOpen = (option) => {
    setStakeData(option);
    setOpen(true);
    getBalance();
    const todaydate = new Date();
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    const formattedCurrentDate = todaydate.toLocaleString("en-GB", options);
    setCurrentDate(formattedCurrentDate);
    setendDate("");
    setaccuralDate("");
    setDuration("");
  };

  const handleClose = () => {
    setOpen(false);
    setErrorState(false);
    setErrorMsg("");
    setstakeValue("");
    setuserTotlaInterest("");
    setTotalFlexible("");
    setCurrentDate("");
    setendDate("");
    setaccuralDate("");
    setDuration("");
  };

  const selectDuration = (data) => {
    setDuration(data.duration);
    setApy(data.durationApy);
    if (Duration !== "") {
      setErrorState(false);
      setErrorMsg("");
    }
    setErrorState(false);
    const currentDate = new Date();
    const endDate = new Date(currentDate);
    endDate.setDate(currentDate.getDate() + data.duration);
    setstakeCurrentDate(currentDate);
    setstakeendDate(endDate);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };

    const formattedCurrentDate = currentDate.toLocaleString("en-GB", options);
    const formattedEndDate = endDate.toLocaleString("en-GB", options);
    setCurrentDate(formattedCurrentDate);
    setendDate(formattedEndDate);
    setaccuralDate(formattedEndDate);
    setstakeValue("");
    setuserTotlaInterest("");
  };

  const stakeAmount = (e) => {
    setbuttonStatus(false);
    try {
      const value = e.target.value;
      if (Duration == "" && stakeData.type === "fixed") {
        setErrorState(true);
        setErrorMsg(t("choose-duration"));
      } else if (isNaN(e.target.value)) {
        setstakeValue();
        setErrorState(true);
        setErrorMsg(t("only-numbers-are-allowed"));
      }  else if (!value) {
        setErrorState(true);
        setstakeValue();
        setErrorMsg(
          t("enter-amount-stake")
        );
      }   else if (value.length > 15) {
        setstakeValue();
        setErrorState(true);
        setErrorMsg(t("invalid-amount"));
      } else if (value == "00000") {
        setstakeValue();
        setErrorState(true);
        setErrorMsg(t("invalid-amount"));
      } else if (value == "0.0000") {
        setstakeValue();
        setErrorState(true);
        setErrorMsg(t("invalid-amount"));
      } else if (value < stakeData.minimimumStaking) {
        setstakeValue();
        setErrorState(true);
        setErrorMsg(
          `${t("minimum-staking-level")} ${stakeData.minimimumStaking} `
        );
        setstakeValue();
      } else if (value > stakeData.maximimumStaking) {
        setErrorState(true);
        setstakeValue();
        setErrorMsg(
          `${t("maximum-staking-level")} ${stakeData.maximimumStaking} `
        );
      } 
      else {
        setErrorState(false);
        setstakeValue(value);
        setbuttonStatus(true);
      }
     
      var amount = parseFloat(e.target.value);
      if (stakeData.type === "fixed") {
        var dailyinterest = amount * (+apy / 100 / 365);
        var totalInterest = parseFloat(dailyinterest) * Duration;
        setuserTotlaInterest(totalInterest ? totalInterest : 0);
        setuserDailyInterest(dailyinterest ? dailyinterest : 0);
      } else if (stakeData.type === "flexible") {
        var dailyinterestDate = amount * (+stakeData.APRinterest / 100 / 365);
        var totalInterestFlex = parseFloat(dailyinterestDate) * 1;
        setFlexibleEarn(dailyinterestDate ? dailyinterestDate : 0);
        setTotalFlexible(totalInterestFlex);
      }
    } catch (error) {
      // showerrorToast("Please try again later");
    }
  };

  const stake = async (e) => {
    try {
      const currentDate = new Date();
      e.preventDefault();
      setbuttonLoader(true);
      var obj = {
        stakingPlan: stakeData.type === "fixed" ? Duration : 0,
        totalInterest:
          stakeData.type === "fixed" ? +userTotlaInterest : +TotalFlexible,
        dailyinterest:
          stakeData.type == "fixed" ? +userDailyInterest : +FlexibleEarn,
        startDate: stakeData.type == "fixed" ? stakecurrentDate : currentDate,
        endDate: stakeendDate,
        currentAPY: stakeData.type === "fixed" ? +apy : +stakeData.APRinterest,
        stakeMore: stakeData.stakeid,
        stakeAmont: +stakeValue,
        Duration:Duration,
        type: stakeData.type,
      };

      if(availaleBalanceref.current > obj.stakeAmont){
        var data = {
          apiUrl: apiService.confirmStaking,
          payload: obj,
        };
        var resp = await postMethod(data);
        if (resp.status == true) {
          setbuttonLoader(false);
          showSuccessToast(resp.Message);
          handleClose();
          window.location.reload();
        } else {
          setbuttonLoader(false);
          showErrorToast(resp.Message);
        }
      }else{
        setbuttonLoader(false)
        showErrorToast("Insufficient Funds ");
      }
    
    } catch (err) {}
  };

  return (
    <div className="table-responsive table-cont">
      <table className="table">
        <thead>
          <tr className="stake-head font-satoshi thead-bor-bottom">
            <th>{t("currency")}</th>
            <th className="txt-center opt-nowrap pad-left-23">
              {t("reference")} APR / APY
            </th>
            <th className="txt-center opt-nowrap pad-left-23">
              {t("min-staking")}
            </th>
            <th className="txt-center opt-nowrap pad-left-23">
              {t("max-staking")}
            </th>
            <th className="txt-center pad-left-23">{t("term")}</th>
            <th className="opt-btn-flex table-action pad-left-23">
              {t("action")}
            </th>
          </tr>
        </thead>

        <tbody>
          {data.data.length > 0 ? (
            data.data.map((options) => {
              const formatter = new Intl.NumberFormat('en-US');
              return (
                <tr key={options.id}>
                  <td className="table-flex">
                    <img src={options.currencyImage} alt="" />
                    <div className="table-opt-name">
                      {/* <h4 className="opt-name font-satoshi font_14">
                        {options.currencyName}
                      </h4> */}
                      <h3 className="opt-sub font-satoshi font_14">
                        {options.currencySymbol}
                      </h3>
                    </div>
                  </td>
                  <td className="opt-percent font-satoshi font_14 table_center_text pad-left-23">
                    {options.APRinterest} %
                  </td>
                  <td className="opt-percent font-satoshi font_14 table_center_text pad-left-23">
                    { formatter.format(options.minimimumStaking)}

                  </td>
                  <td className="opt-percent font-satoshi font_14 table_center_text pad-left-23">
                    {formatter.format(options.maximimumStaking)}
                  </td>
              
                  <td className="opt-term font-satoshi font_14 table_center_text pad-left-23">
                    {options.type == "fixed" ? t("fixed") : t("flexible")}
                  </td>
                  {loginCheck == true ? (
                    <td className="opt-btn-flex table-action pad-left-23">
                      <button
                        className="opt-btn"
                        onClick={() => handleOpen(options)}
                      >
                        {t("stake-now")}
                      </button>
                    </td>
                  ) : (
                    <td className="opt-btn-flex table-action pad-left-23">
                      <Link to="/login">
                        <button className="opt-btn">
                          {t("login-to-continue")}
                        </button>
                      </Link>
                    </td>
                  )}
                </tr>
              );
            })
          ) : (
            <td colSpan={7} className="text-center">
              <div className="empty_data">
                <div className="empty_data_img">
                  <img
                    src={require("../assets/No-data.png")}
                    alt="no-data"
                    width="100px"
                  />
                </div>
                <div className="no_records_text">{t("no-records-found")}</div>
              </div>
            </td>
          )}
        </tbody>
      </table>

      {/* <div className="paginate font-satoshi">
        <span>
          <i class="fa-solid fa-chevron-left"></i>
        </span>
        <span className="paginate-one">1</span>
        <span>2</span>
        <span>
          <i class="fa-solid fa-chevron-right"></i>
        </span>
      </div> */}

      {/* staking fixed */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modals">
          <div className="container">
            <div className="row">
              {/* left - staking flexible*/}
              <div className="col-lg-6 modal_left_bg">
                <div className="left">
                  <div className="modal-left-header">
                    <img
                      src={stakeData.currencyImage}
                      alt="bitcoin"
                      className="bitcoin-img"
                    />
                    <span className="stake-flexi">
                      {t("staking")} - {stakeData.type}
                    </span>
                  </div>
                  <div className="modal-left-main">
                    {stakeData.type === "fixed" ? (
                      <div className="modal-selectDate">
                        <h6 className="modal-select-title">
                          {t("select-date")}
                        </h6>
                        <div className="modal-flex">
                          {stakeData.duration === "" ||
                          stakeData.duration === undefined ||
                          stakeData.duration === null
                            ? ""
                            : stakeData.duration.length > 0
                            ? stakeData.duration.map((days) => {
                                return (
                                  <div
                                    key={days.duration}
                                    className={`modal-days ${
                                      Duration === days.duration
                                        ? "modal-days-active"
                                        : ""
                                    }`}
                                    onClick={() => selectDuration(days)}
                                  >
                                    <p>{days.duration} D</p>
                                  </div>
                                );
                              })
                            : ""}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {stakeData.type === "fixed" ? (
                      <div className="modal-left-flex">
                        <h6 className="modal-titles">{t("reference")} APY</h6>
                        <h5 className="modal-titles-green">{apy} %</h5>
                      </div>
                    ) : (
                      <div className="modal-left-flex">
                        <h6 className="modal-titles">{t("reference")} APR</h6>
                        <h5 className="modal-titles-green">
                          {stakeData.APRinterest} %
                        </h5>
                      </div>
                    )}
                    <div className="modal-left-flex">
                      {" "}
                      <h6 className="modal-titles">{t("term")} </h6>
                      <h5 className="modal-right-titles">{stakeData.type}</h5>
                    </div>
                    {stakeData.type === "fixed" ? (
                      <div className="modal-left-flex">
                        {" "}
                        <h6 className="modal-titles">
                          {t("redemption-period")}{" "}
                        </h6>
                        <h5 className="modal-right-titles">
                          {Duration === "" ||
                          Duration === undefined ||
                          Duration === null
                            ? 0
                            : Duration}{" "}
                          {t("days")}
                        </h5>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="modal-left-foot">
                    <h6 className="modal-left-title font-600">{t("amount")}</h6>
                    <div className="modal-input">
                      <input
                        type="text"
                        id="numberInput"
                        min="0"
                        maxLength={9}
                        name="tfa"
                        onKeyDown={(evt) =>
                          ["e", "E", "+", "-"].includes(evt.key) &&
                          evt.preventDefault()
                        }
                        onInput={(e) => {
                          e.target.value = e.target.value
                            .replace(/[^0-9.]/g, "")
                            .replace(/(\..*)\./g, "$1");
                        }}
                        placeholder={t("enter-amount-stake")}
                        className="modal-input-num"
                        value={stakeValue}
                        onChange={stakeAmount}
                      />
                      {errorState === true ? (
                        <p className="text-red font-satoshi"> {errorMsg}</p>
                      ) : (
                        ""
                      )}
                      <span className="modal-span1">
                        {stakeData.currencySymbol}
                      </span>
                      <p className="modal-span2 " onClick={caluculateMax}>
                        {" "}
                        MAX
                      </p>
                    </div>
                    <div className="modal-left-flex">
                      <h5 className="modal-titles">{t("min-amount")}</h5>
                      <h4 className="modal-right-titles">
                        {stakeData.minimimumStaking} {stakeData.currencySymbol}
                      </h4>
                    </div>
                    <div className="modal-left-flex">
                      <h5 className="modal-titles">{t("max-amount")}</h5>
                      <h4 className="modal-right-titles">
                        {stakeData.maximimumStaking} {stakeData.currencySymbol}
                      </h4>
                    </div>
                    <div className="modal-left-flex">
                      <h5 className="modal-titles">{t("avail-balance")}</h5>
                      <h4 className="modal-right-titles">
                        {availaleBalanceref.current} {stakeData.currencySymbol}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>

              {/* right - Preview*/}
              <div className="col-lg-6 right-col">
                <div className="right">
                  <div className="modal-right-header modal-left-flex">
                    <h5 className="stake-flexi">{t("preview")}</h5>

                    <i
                      className="fa-regular fa-circle-xmark cross_circle"
                      onClick={handleClose}
                    ></i>
                  </div>

                  <div className="modal-right-main">
                    <div className="modal-right-main-flex">
                      {stakeData.type === "fixed" ? (
                        <>
                          {/* IMG */}

                          <div className="straight-img">
                            <img src={line} alt="line" className="line-pic" />
                          </div>
                          {/* RIGHT CONTENT */}
                          <div className="modal-flex-col">
                            <div className="modal-left-flex">
                              <h6 className="modal-titles">
                                {t("subscription-date")}
                              </h6>
                              <h5 className="modal-right-titles">
                                {currentDate ? currentDate : "--.--"}
                              </h5>
                            </div>
                            <div className="modal-left-flex">
                              {" "}
                              <h6 className="modal-titles">
                                {t("accrual-date")}
                              </h6>
                              <h5 className="modal-right-titles">
                                {accuralDate ? accuralDate : "--.--"}
                              </h5>
                            </div>
                            <div className="modal-left-flex">
                              {" "}
                              <h6 className="modal-titles">
                                {t("profit-dis-date")}
                              </h6>
                              <h5 className="modal-right-titles">
                                {endDate ? endDate : "--.--"}
                              </h5>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="modal-flex-col">
                          <div className="modal-left-flex">
                            <>
                              <h6 className="modal-titles">
                                {t("subscription-date")}
                              </h6>
                              <h5 className="modal-right-titles">
                                {currentDate ? currentDate : "--.--"}
                              </h5>
                            </>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="modal-left-flex pad--border">
                      {" "}
                      <h6 className="modal-titles">{t("stake-amount")}</h6>
                      <h5 className="modal-right-titles">
                        {stakeValue} {stakeData.currencySymbol}
                      </h5>
                    </div>
                    <div className="modal-estimated">
                      <h6 className="modal-left-title font-600">
                        {t("esti-returns")}
                      </h6>
                      <div className="modal-left-flex">
                        <h5 className="modal-titles">
                          {stakeData.currencySymbol} {t("earnings")}
                        </h5>
                        {stakeData.type === "fixed" ? (
                          <h4 className="modal-titles-green">
                            {userTotlaInterest
                              ? userTotlaInterest.toFixed(6)
                              : "00.00"}
                          </h4>
                        ) : (
                          <h4 className="modal-titles-green">
                            {TotalFlexible ? TotalFlexible.toFixed(6) : "00.00"}
                          </h4>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-right-foot">
                  {buttonLoader == true ? (
                    <button className="modal-right-btn ">
                      {t("loading")}...
                    </button>
                  ) : buttonStatus == true ? (
                    <button className="modal-right-btn " onClick={stake}>
                      {t("stake")}
                    </button>
                  ) : (
                    <button className="modal-right-btn disabled" disabled>
                      {t("stake")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default StakeOptTable;
