import React from "react";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData2";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Side_bar from "./Side_bar";
import RewardTable from "./RewardTable";
import { useTranslation } from "react-i18next";

const Rewards = () => {
  const { t } = useTranslation();

  return (
    <>
      <section>
        <Header />
      </section>

      <main className="dashboard_main assets_main">
        <div className="container-fluid">
          <div className="row vh-100">
            <div className="col-lg-2 p-0">
              <Side_bar />
            </div>

            <div className="col-lg-10 col-md-12">
              <div className="pad-l-r">
                <section className="dashboard_content">
                  <div className="row">
                    <div className="buy_head">
                      <div className="buy-rewards">
                        <span className="reward-title">{t("my-rewards")}</span>
                        {/* <div className="stake-search-container">
                        <input
                          type="text"
                          placeholder="Search"
                          className="stake-input"
                        />
                        <i class="fa-solid fa-magnifying-glass"></i>
                      </div> */}
                      </div>
                      <RewardTable />
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Rewards;
