import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import Header from "./Header";
import { v4 as uuidv4 } from "uuid";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useTranslation } from "react-i18next";

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isFocused ? "#33c2b9" : null,
      color: isFocused ? "#fff" : "#000",
      cursor: isDisabled ? "not-allowed" : "pointer",
    };
  },
};

export default function Register() {
  const [referalGet, setReferalGet] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    let str = window.location.href;
    const letters = str.includes("?");
    if (letters) {
      setReferalGet(window.location.href.split("=")[1]);
      let locationData = window.location.href.split("=")[1];
      let formData = { ...formValue, ...{ referral_code: locationData } };
      setFormValue(formData);
    } else {
    }
  }, []);

  const [toastId, setToastId] = useState(null);

  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const showErrorToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.error(message);
    setToastId(newToastId);
  };

  const initialFormValue = {
    name: "",
    email: "",
    phonenumber: "",
    businesstype: "",
    businessname: "",
    incorporationplace: "",
    password: "",
    confirmPassword: "",
    referral_code: "",
  };

  const options = countryList().getData();

  const [validationnErr, setvalidationnErr] = useState("");
  const [formValue, setFormValue] = useState(initialFormValue);
  const [nameValidate, setnameValidate, nameValidateref] = useState(false);
  const [emailValidate, setemailValidate, emailValidateref] = useState(false);
  const [phoneNumberValidate, setPhoneNumberValidate, phoneNumberValidateref] =
    useState(false);
  // const [
  //   businessTypeValidate,
  //   setBusinessTypeValidate,
  //   businessTypeValidateref,
  // ] = useState(false);

  // new business type
  const [
    businessTypeValidate,
    setBusinessTypeValidate,
    businessTypeValidateref,
  ] = useState(false);

  const [
    businessNameValidate,
    setBusinessNameValidate,
    businessNameValidateref,
  ] = useState(false);

  const [
    corporationPlaceValidate,
    setCorporationPlaceValidate,
    corporationPlaceValidateref,
  ] = useState(false);

  const [passwordValidate, setpasswordValidate, passwordValidateref] =
    useState(false);

  const [
    confirmPasswordValidate,
    setconfirmPasswordValidate,
    confirmPasswordValidateref,
  ] = useState(false);

  const [isChecked, setIschecked] = useState(false);
  const [Terms, setTerms, Termsref] = useState(false);
  const [termsValidation, setTermsValidation] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState("password");
  const [passHidconf, setPasshideconf] = useState(false);
  const [inputTypeconf, setinputTypeconf] = useState("password");

  const [registerType, setRegisterType, registerTyperef] =
    useState("Individuals");

  const {
    name,
    email,
    phonenumber,
    businesstype,
    businessname,
    incorporationplace,
    password,
    confirmPassword,
    referral_code,
  } = formValue;

  const businessOptions = [
    { value: "Operating company", label: t("operating-company") },
    { value: "Non-operating company", label: t("non-operating-company") },
    { value: "External assest manager", label: t("external-asset-manager") },
    { value: "Family office", label: t("family-office") },
    { value: "Financial intermediary", label: t("financial-intermediary") },
    { value: "Broker dealers", label: t("broker-dealers") },
    { value: "Trust", label: t("trust") },
    { value: "Broker dealers", label: t("broker-dealers") },
    { value: "Foundation", label: t("foundation") },
    { value: "VARA/VASP", label: "VARA/VASP" },
    { value: "Crypto exchange", label: t("crypto-exchange") },
  ];

  const navigate = useNavigate();

  const createUid = uuidv4();
  const createdUuid = createUid.split("-")[0].toString();

  const handleKeyDown = (e) => {
    if (e.key === " " && e.target.selectionStart === 0) {
      e.preventDefault();
    }
  };
  const handleChange = async (e) => {
    const { name, value } = e.target;
    // if ( e.target.value.startsWith(" ")) {
    //   return; // Prevent setting the value if it starts with a space
    // }else{
    let sanitizedValue = value.replace(/\s/g, "");
    if (name === "phonenumber") {
      sanitizedValue = e.target.value.replace(/\D/g, "").slice(0, 10);
    }
    let formData = { ...formValue, ...{ [name]: e.target.value } };
    setFormValue(formData);
    validate(formData);
    // }
  };

  const handleSelectChange = (selectedOption) => {
    setFormValue({ ...formValue, incorporationplace: selectedOption.value });
    validate({ ...formValue, incorporationplace: selectedOption.value });
  };

  // New business Type
  const handleBusinessChange = (selectedOption) => {
    setFormValue({ ...formValue, businesstype: selectedOption.value });
    validate({ ...formValue, businesstype: selectedOption.value });
  };

  const handlePhoneChange = (value) => {
    setFormValue({ ...formValue, phonenumber: value });
    validate({ ...formValue, phonenumber: value });
  };

  const typeChange = (value) => {
    setRegisterType(value);
    setFormValue(initialFormValue);
    setnameValidate(false);
    setemailValidate(false);
    setPhoneNumberValidate(false);
    // setBusinessTypeValidate(false);
    setBusinessTypeValidate(false);
    setBusinessNameValidate(false);
    setCorporationPlaceValidate(false);
    setpasswordValidate(false);
    setconfirmPasswordValidate(false);
    setIschecked(false);
    setTerms(false);
    setTermsValidation(false);
    setvalidationnErr("");
  };

  const handleTerms = (event) => {
    setIschecked(event.target.checked);
    setTerms(event.target.checked);
    setTermsValidation(!event.target.checked);
  };

  const validate = (values) => {
    let errors = {};
    const username = values.email.split("@")[0];

    // if (registerTyperef.current == "Corporates") {
    if (!values.name) {
      errors.name = t("name-required-field");
      setnameValidate(true);
    } else if (!/^[a-zA-Z]/.test(values.name)) {
      errors.name = t("username-must-start-letter");
      setnameValidate(true);
    } else if (values.name.length < 3 || values.name.length > 25) {
      errors.name = t("name-nust-3-25-char");
      setnameValidate(true);
    }
    //  else if (!/^[a-zA-Z0-9_]+$/.test(values.name)) {
    //   errors.name = t("username-only-contain-letters-num-underscores");
    //   setnameValidate(true);
    // }
    //  else {
    //   setnameValidate(false);
    // }
    // }
    else if (!values.email) {
      errors.email = t("email-required-field");
      setemailValidate(true);
    } else if (
      !/^[a-zA-Z0-9.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = t("invalid-email-address");
      setemailValidate(true);
    }
    // else if (username.length < 3 || username.length > 30) {
    //   errors.email = t("email-username-must-b/w-3-30");
    //   setemailValidate(true);
    // }
    else if (!/^[a-zA-Z0-9.]+$/i.test(username)) {
      errors.email = t("only-letters-numbers-and-periods-allowed");
      setemailValidate(true);
    } else if (!/[a-zA-Z]/.test(username)) {
      errors.email = t("email-username-contain-atleast-one-letter");
      setemailValidate(true);
    }
    // \ else if (values.email.length > 50) {
    //   errors.email = t("email-address-too-long");
    //   setemailValidate(true);
    // }
    //  else {
    //   setemailValidate(false);
    // }
    else if (values.phonenumber == "") {
      setPhoneNumberValidate(true);
      errors.phonenumber = t("ph-no-required");
    } else if (values.phonenumber.length < 5) {
      errors.phonenumber = t("ph-no-should-not-below-5-numbers");
      setPhoneNumberValidate(true);
    }
    // else {
    //   setPhoneNumberValidate(false);
    // }
    else if (registerTyperef.current == "Corporates") {
      // if (values.businesstype == "") {
      //   setBusinessTypeValidate(true);
      //   errors.businesstype = "Business type is required !";
      // } else {
      //   setBusinessTypeValidate(false);
      // }

      // new business type
      if (values.businesstype == "") {
        setBusinessTypeValidate(true);
        errors.businesstype = t("business-type-required");
      }
      //  else {
      //   setBusinessTypeValidate(false);
      // }
      else if (values.businessname == "") {
        setBusinessNameValidate(true);
        errors.businessname = t("business-name-required");
      }
      // else {
      //   setBusinessNameValidate(false);
      // }
      else if (values.incorporationplace == "") {
        setCorporationPlaceValidate(true);
        errors.incorporationplace = t("incorporation-place-required");
      } else if (values.password == "") {
        setpasswordValidate(true);
        errors.password = t("password-required");
      } else if (values.password.length < 12 || values.password.length > 25) {
        setpasswordValidate(true);
        errors.password = t("passoword-should-not-below-5-above-25");
      } else if (!values.password.match(/[a-z]/g)) {
        setpasswordValidate(true);
        errors.password = t("please-enter-at-least-lower-char");
      } else if (!values.password.match(/[A-Z]/g)) {
        setpasswordValidate(true);
        errors.password = t("please-enter-at-least-upper-char");
      } else if (!values.password.match(/[0-9]/g)) {
        setpasswordValidate(true);
        errors.password = t("please-enter-one-digit-char");
      } else if (!values.password.match(/[!@#$%^&*]/g)) {
        setpasswordValidate(true);
        errors.password = t("please-enter-at-least-one-special-char");
      }
      //  else {
      //   setpasswordValidate(false);
      // }
      else if (!values.confirmPassword) {
        errors.confirmPassword = t("confirm-password-required-field");
        setconfirmPasswordValidate(true);
      } else if (
        values.password &&
        values.confirmPassword &&
        values.password !== values.confirmPassword
      ) {
        errors.confirmPassword = t("password-confirm-password-does-not-match");
        setconfirmPasswordValidate(true);
      }
      // else {
      //   setconfirmPasswordValidate(false);
      // }
      else if (!Terms) {
        errors.terms = t("terms-required-field");
        setTermsValidation(true);
      } else {
        errors.terms = "";
        setTermsValidation(false);
        setconfirmPasswordValidate(false);
        setpasswordValidate(false);
        setPhoneNumberValidate(false);
        setemailValidate(false);
        setnameValidate(false);
        setCorporationPlaceValidate(false);
        setBusinessNameValidate(false);
        setBusinessTypeValidate(false);
      }
      //  else {
      //   setCorporationPlaceValidate(false);
      //   setBusinessNameValidate(false);
      //   setBusinessTypeValidate(false);
      //   setPhoneNumberValidate(false);
      //   setemailValidate(false);
      //   setnameValidate(false);
      // }
    } else if (values.password == "") {
      setpasswordValidate(true);
      errors.password = t("password-required");
    } else if (values.password.length < 12 || values.password.length > 25) {
      setpasswordValidate(true);
      errors.password = t("passoword-should-not-below-5-above-25");
    } else if (!values.password.match(/[a-z]/g)) {
      setpasswordValidate(true);
      errors.password = t("please-enter-at-least-lower-char");
    } else if (!values.password.match(/[A-Z]/g)) {
      setpasswordValidate(true);
      errors.password = t("please-enter-at-least-upper-char");
    } else if (!values.password.match(/[0-9]/g)) {
      setpasswordValidate(true);
      errors.password = t("please-enter-one-digit-char");
    } else if (!values.password.match(/[!@#$%^&*]/g)) {
      setpasswordValidate(true);
      errors.password = t("please-enter-at-least-one-special-char");
    }
    //  else {
    //   setpasswordValidate(false);
    // }
    else if (!values.confirmPassword) {
      errors.confirmPassword = t("confirm-password-required-field");
      setconfirmPasswordValidate(true);
    } else if (
      values.password &&
      values.confirmPassword &&
      values.password !== values.confirmPassword
    ) {
      errors.confirmPassword = t("password-confirm-password-does-not-match");
      setconfirmPasswordValidate(true);
    }
    // else {
    //   setconfirmPasswordValidate(false);
    // }
    else if (!Terms) {
      errors.terms = t("terms-required-field");
      setTermsValidation(true);
    } else {
      errors.terms = "";
      setTermsValidation(false);
      setconfirmPasswordValidate(false);
      setpasswordValidate(false);
      setPhoneNumberValidate(false);
      setemailValidate(false);
      setnameValidate(false);
    }
    setvalidationnErr(errors);
    return errors;
  };

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType("text");
    } else {
      setPasshide(false);
      setinputType("password");
    }
  };

  const passwordHideconf = (data) => {
    if (data == "hide") {
      setPasshideconf(true);
      setinputTypeconf("text");
    } else {
      setPasshideconf(false);
      setinputTypeconf("password");
    }
  };

  const formSubmit = async (payload) => {
    validate(formValue);
    formValue["UUID"] = createdUuid;
    if (registerTyperef.current === "Individuals") {
      if (
        nameValidateref.current === false &&
        emailValidateref.current === false &&
        phoneNumberValidateref.current === false &&
        passwordValidateref.current === false &&
        confirmPasswordValidateref.current === false &&
        Terms === true
      ) {
        formValue["registertype"] = "Individuals";
        var data = {
          apiUrl: apiService.signup,
          payload: formValue,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setFormValue(initialFormValue);
        localStorage.setItem("useremail", resp.email);
        setbuttonLoader(false);
        if (resp.status) {
          showSuccessToast(resp.Message);
          navigate("/verification");

          localStorage.setItem("registe_type", registerTyperef.current);
        } else {
          showErrorToast(resp.Message);
        }
      } else {
      }
    } else {
      if (
        nameValidateref.current === false &&
        emailValidateref.current === false &&
        phoneNumberValidateref.current === false &&
        businessTypeValidateref.current === false &&
        businessNameValidateref.current === false &&
        corporationPlaceValidateref.current === false &&
        passwordValidateref.current === false &&
        confirmPasswordValidateref.current === false &&
        Terms === true
      ) {
        formValue["registertype"] = "Corporates";
        var data = {
          apiUrl: apiService.signup,
          payload: formValue,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setFormValue(initialFormValue);
        localStorage.setItem("useremail", resp.email);
        setbuttonLoader(false);
        if (resp.status) {
          showSuccessToast(resp.Message);
          navigate("/verification");
          localStorage.setItem("registe_type", registerTyperef.current);
        } else {
          showErrorToast(resp.Message);
        }
      } else {
      }
    }
  };

  return (
    <>
      <div>
        <Header />
        <div className="register">
          <div className="container">
            <div className="row reg-row">
              <div className="col-lg-4">
                <div className="register-left">
                  <img src={require("../assets/register.webp")} />
                  <h4>{t("trade-earn-rewards")}</h4>
                  <h5>
                    {t("trade-earn-rewards-content1")} <br />{" "}
                    {t("trade-earn-rewards-content2")}
                  </h5>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="register-card reg-mt">
                  <span class="heading">{t("signup-fidex")}</span>

                  {/* toggle buttons (email and phone)*/}
                  <div class="nav nav-tabs mt-2" id="nav-tab" role="tablist">
                    <button
                      class="nav-link active"
                      id="nav-home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home"
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                      onClick={() => typeChange("Individuals")}
                    >
                      {t("individuals")}
                    </button>
                    <button
                      class="nav-link "
                      id="nav-profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-profile"
                      type="button"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                      onClick={() => typeChange("Corporates")}
                    >
                      {t("corporates")}
                    </button>
                  </div>

                  <div class="tab-content" id="nav-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="nav-home"
                      role="tabpanel"
                      aria-labelledby="nav-home-tab"
                      tabindex="0"
                    >
                      <div className="input-groups">
                        <h6 className="input-label">{t("name")}</h6>
                        <input
                          type="text"
                          name="name"
                          minLength={3}
                          maxLength={25}
                          value={name}
                          onKeyDown={handleKeyDown}
                          onChange={handleChange}
                          className="input-field"
                          placeholder={t("enter-name")}
                        />
                        {validationnErr && validationnErr.name && (
                          <p className="errorcss">{validationnErr.name}</p>
                        )}
                      </div>
                      <div className="input-groups ">
                        <h6 className="input-label">{t("email")}</h6>
                        <input
                          type="text"
                          name="email"
                          value={email}
                          min="0"
                          maxLength={250}
                          onChange={handleChange}
                          className="input-field"
                          placeholder={t("enter-email")}
                        />
                        {validationnErr && validationnErr.email && (
                          <p className="errorcss">{validationnErr.email}</p>
                        )}
                      </div>
                      <div className="input-groups">
                        <h6 className="input-label">{t("phone-number")}</h6>
                        <PhoneInput
                          country={"us"}
                          value={phonenumber}
                          onChange={handlePhoneChange}
                          inputProps={{
                            name: "phonenumber",
                            required: true,
                            className: "input-phone primary-reg-phone",
                          }}
                        />
                        {/* <input
                          type="number"
                          name="phonenumber"
                          value={phonenumber}
                          onChange={handleChange}
                          maxLength={10}
                          pattern="\d*"
                          className="input-field"
                          placeholder="Enter the Phone Number"
                        /> */}
                        {validationnErr && validationnErr.phonenumber && (
                          <p className="errorcss">
                            {validationnErr.phonenumber}
                          </p>
                        )}
                      </div>
                      <div className="input-groups">
                        <h6 className="input-label">{t("password")}</h6>
                        <div className="flex_input_posion">
                          <input
                            type={inputType}
                            name="password"
                            value={password}
                            minLength={12}
                            maxLength={25}
                            onChange={handleChange}
                            className="input-field"
                            placeholder={t("enter-password")}
                          />
                          {passHide == true ? (
                            <i
                              class="fa-regular fa-eye eyeicons"
                              onClick={() => passwordHide("show")}
                            ></i>
                          ) : (
                            <i
                              class="fa-regular fa-eye-slash eyeicons"
                              onClick={() => passwordHide("hide")}
                            ></i>
                          )}
                        </div>
                        {validationnErr && validationnErr.password && (
                          <p className="errorcss">{validationnErr.password}</p>
                        )}
                        {/* <img
                          src={require("../assets/Eye.png")}
                          width="20px"
                          className="eyeicons"
                        /> */}
                      </div>
                      <div className="input-groups">
                        <h6 className="input-label">{t("confirm-password")}</h6>
                        <div className="flex_input_posion">
                          <input
                            type={inputTypeconf}
                            name="confirmPassword"
                            value={confirmPassword}
                            minLength={12}
                            maxLength={25}
                            onChange={handleChange}
                            className="input-field"
                            placeholder={t("re-enter-password")}
                          />
                          {passHidconf == true ? (
                            <i
                              class="fa-regular fa-eye eyeicons"
                              onClick={() => passwordHideconf("show")}
                            ></i>
                          ) : (
                            <i
                              class="fa-regular fa-eye-slash eyeicons"
                              onClick={() => passwordHideconf("hide")}
                            ></i>
                          )}
                        </div>
                        {validationnErr && validationnErr.confirmPassword && (
                          <p className="errorcss">
                            {validationnErr.confirmPassword}
                          </p>
                        )}
                        {/* <img
                          src={require("../assets/Eye.png")}
                          width="20px"
                          className="eyeicons"
                        /> */}
                      </div>
                      <div className="input-groups">
                        <h6 className="input-label">{t("referral-id")}</h6>
                        {referalGet == null ||
                        referalGet == undefined ||
                        referalGet == "" ? (
                          <input
                            type="text"
                            name="referral_code"
                            maxLength={13}
                            value={referral_code}
                            onChange={handleChange}
                            className="input-field"
                            placeholder={t("enter-referral-id")}
                          />
                        ) : (
                          <input
                            type="text"
                            name="referral_code"
                            maxLength={13}
                            value={referral_code}
                            disabled
                            onChange={handleChange}
                            className="input-field"
                            placeholder={t("enter-referral-id")}
                          />
                        )}
                      </div>
                      <div className="terms">
                        <input
                          className="input-field"
                          type="checkbox"
                          id="cb1"
                          value={isChecked}
                          name="check"
                          onChange={handleTerms}
                        />
                        <div className="custom-check"></div>
                        <label className="terms-label" htmlFor="cb1">
                          {t("i-agree-to")}{" "}
                          <a href="/terms" target="_blank">
                            {t("reg-terms")}
                          </a>{" "}
                          &{" "}
                          <a href="/privacy" target="_blank">
                            {t("reg-privacy")}
                          </a>
                        </label>
                      </div>

                      {termsValidation ? (
                        <p className="errorcss">
                          {t("terms-conditions-required")}
                        </p>
                      ) : (
                        ""
                      )}

                      <div className="Submit" onClick={formSubmit}>
                        {buttonLoader == false ? (
                          <button>{t("submit")}</button>
                        ) : (
                          <button>{t("loading")} ...</button>
                        )}
                      </div>

                      <div className="foot">
                        <p>
                          {t("already-account")}{" "}
                          <Link to="/login"> {t("signin")}</Link>
                        </p>
                      </div>
                    </div>

                    <div
                      class="tab-pane fade"
                      id="nav-profile"
                      role="tabpanel"
                      aria-labelledby="nav-profile-tab"
                      tabindex="0"
                    >
                      {/* corporate name */}
                      <div className="input-groups">
                        <h6 className="input-label">{t("name")}</h6>
                        <input
                          type="text"
                          name="name"
                          minLength={3}
                          maxLength={25}
                          value={name}
                          onKeyDown={handleKeyDown}
                          onChange={handleChange}
                          className="input-field"
                          placeholder={t("enter-name")}
                        />
                        {validationnErr && validationnErr.name && (
                          <p className="errorcss">{validationnErr.name}</p>
                        )}
                      </div>
                      {/* corporate email  */}
                      <div className="input-groups">
                        <h6 className="input-label">{t("email")}</h6>
                        <input
                          type="text"
                          name="email"
                          value={email}
                          minLength={3}
                          maxLength={250}
                          onChange={handleChange}
                          className="input-field"
                          placeholder={t("enter-email")}
                        />
                        {validationnErr && validationnErr.email && (
                          <p className="errorcss">{validationnErr.email}</p>
                        )}
                      </div>
                      {/* cor-phone */}
                      <div className="input-groups">
                        <h6 className="input-label">{t("phone-no")}</h6>
                        <PhoneInput
                          country={"us"}
                          value={phonenumber}
                          onChange={handlePhoneChange}
                          inputProps={{
                            name: "phonenumber",
                            required: true,
                            className: "input-phone",
                          }}
                        />
                        {/* <div className="select-business">
                          <input
                            className="input-field phone_input"
                            placeholder="Enter the Number"
                          />
                          <span className="set-phoneIcon">
                            <div className="set-phone-no">+91 </div>
                            <i class="fa-solid fa-caret-down"></i>
                          </span>
                        </div> */}
                        {validationnErr && validationnErr.phonenumber && (
                          <p className="errorcss">
                            {validationnErr.phonenumber}
                          </p>
                        )}
                      </div>

                      {/* Old business type */}
                      {/* <div className="input-groups select-wrapper">
                        <h6 className="input-label">Business Type</h6>
                        <select
                          name="businesstype"
                          value={businesstype}
                          onChange={handleChange}
                          className="input-field"
                        >
                          <option value="" disabled className="disabled_text">
                            Please Select
                          </option>
                          <option value="Operating company">
                            Operating company
                          </option>
                          <option value="Non-operating company">
                            Non-operating company
                          </option>
                          <option value="External assest manager">
                            External assest manager
                          </option>
                          <option value="Family office">Family office</option>
                          <option value="Financial intermediary">
                            Financial intermediary
                          </option>
                          <option value="Broker dealers">Broker dealers</option>
                          <option value="Trust">Trust</option>
                          <option value="Foundation">Foundation</option>
                          <option value="VARA/VASP">VARA/VASP</option>
                          <option value="Crypto exchange">
                            Crypto exchange
                          </option>
                        </select>
                        <i class="fa-solid fa-caret-down select-arrow"></i>
                      </div>
                      {validationnErr && validationnErr.businesstype && (
                        <p className="errorcss mt-1">
                          {validationnErr.businesstype}
                        </p>
                      )} */}

                      {/* New Business Type */}
                      <div className="input-groups select-wrapper">
                        <h6 className="input-label mb-2">
                          {t("business-type")}
                        </h6>
                        <Select
                          options={businessOptions}
                          value={options.find(
                            (option) => option.value === businesstype
                          )}
                          onChange={(selectedOption) =>
                            handleBusinessChange(selectedOption)
                          }
                          classNamePrefix="custom-select"
                          placeholder={t("please-select")}
                          minLength={0}
                          maxLength={60}
                          styles={colourStyles}
                          className="reg-select-dropdown"
                        />

                        <i class="fa-solid fa-caret-down select-arrow"></i>
                      </div>

                      {validationnErr && validationnErr.businesstype && (
                        <p className="errorcss mt-1">
                          {validationnErr.businesstype}
                        </p>
                      )}

                      {/* business name */}
                      <div className="input-groups">
                        <h6 className="input-label">{t("business-name")}</h6>
                        <input
                          type="text"
                          name="businessname"
                          value={businessname}
                          minLength={3}
                          onKeyDown={handleKeyDown}
                          maxLength={150}
                          onChange={handleChange}
                          className="input-field"
                          placeholder="Enter the Business Name"
                        />
                        {validationnErr && validationnErr.businessname && (
                          <p className="errorcss">
                            {validationnErr.businessname}
                          </p>
                        )}
                      </div>
                      {/* place of incorporation */}
                      <div className="input-groups select-wrapper">
                        <h6 className="input-label mb-2">
                          {t("place-of-incorporation")}
                        </h6>
                        <Select
                          options={options}
                          value={options.find(
                            (option) => option.value === incorporationplace
                          )}
                          onChange={(selectedOption) =>
                            handleSelectChange(selectedOption)
                          }
                          classNamePrefix="custom-select"
                          placeholder={t("select")}
                          styles={colourStyles}
                          className="reg-select-dropdown"
                        />
                        <i class="fa-solid fa-caret-down select-arrow"></i>
                        {/* <div className="select-business">
                          <input
                            className="input-field"
                            placeholder="Please Select"
                          />
                          <span className="set-icon">
                            <i class="fa-solid fa-caret-down"></i>
                          </span>
                        </div> */}
                      </div>
                      {validationnErr && validationnErr.incorporationplace && (
                        <p className="errorcss mt-1">
                          {validationnErr.incorporationplace}
                        </p>
                      )}
                      <div className="input-groups icons">
                        <h6 className="input-label">{t("password")}</h6>
                        <div className="flex_input_posion">
                          <input
                            type={inputType}
                            name="password"
                            value={password}
                            minLength={12}
                            maxLength={25}
                            onChange={handleChange}
                            className="input-field"
                            placeholder={t("enter-password")}
                          />
                          {passHide == true ? (
                            <i
                              class="fa-regular fa-eye eyeicons"
                              onClick={() => passwordHide("show")}
                            ></i>
                          ) : (
                            <i
                              class="fa-regular fa-eye-slash eyeicons"
                              onClick={() => passwordHide("hide")}
                            ></i>
                          )}
                        </div>
                        {validationnErr && validationnErr.password && (
                          <p className="errorcss">{validationnErr.password}</p>
                        )}
                        {/* <img
                          src={require("../assets/Eye.png")}
                          width="20px"
                          className="eyeicons"
                        /> */}
                      </div>
                      <div className="input-groups icons">
                        <h6 className="input-label">{t("confirm-password")}</h6>
                        <div className="flex_input_posion">
                          <input
                            type={inputTypeconf}
                            name="confirmPassword"
                            value={confirmPassword}
                            minLength={12}
                            maxLength={25}
                            onChange={handleChange}
                            className="input-field"
                            placeholder={t("re-enter-password")}
                          />
                          {passHidconf == true ? (
                            <i
                              class="fa-regular fa-eye eyeicons"
                              onClick={() => passwordHideconf("show")}
                            ></i>
                          ) : (
                            <i
                              class="fa-regular fa-eye-slash eyeicons"
                              onClick={() => passwordHideconf("hide")}
                            ></i>
                          )}
                        </div>
                        {validationnErr && validationnErr.confirmPassword && (
                          <p className="errorcss">
                            {validationnErr.confirmPassword}
                          </p>
                        )}
                        {/* <img
                          src={require("../assets/Eye.png")}
                          width="20px"
                          className="eyeicons"
                        /> */}
                      </div>
                      <div className="input-groups">
                        <h6 className="input-label">{t("referral-id")}</h6>
                        <input
                          type="text"
                          name="referral_code"
                          maxLength={13}
                          value={referral_code}
                          onChange={handleChange}
                          className="input-field"
                          placeholder={t("enter-referral-id")}
                        />
                      </div>
                      <div className="terms">
                        <input
                          className="input-field"
                          type="checkbox"
                          id="cb1"
                          value={isChecked}
                          name="check"
                          onChange={handleTerms}
                        />
                        <div className="custom-check"></div>
                        <p>
                          {t("i-agree-to")}{" "}
                          <a href="/terms" target="_blank">
                            {t("reg-terms")}
                          </a>{" "}
                          {t("reg-and")}{" "}
                          <a href="/privacy" target="_blank">
                            {t("reg-privacy")}
                          </a>
                        </p>
                      </div>
                      {termsValidation ? (
                        <p className="errorcss">
                          {t("terms-conditions-required")}
                        </p>
                      ) : (
                        ""
                      )}

                      <div className="Submit" onClick={formSubmit}>
                        {buttonLoader == false ? (
                          <button>{t("submit")}</button>
                        ) : (
                          <button>{t("loading")} ...</button>
                        )}
                      </div>

                      <div className="foot">
                        <p>
                          {t("already-account")}{" "}
                          <Link to="/login">{t("signin")}</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
