import React, { useEffect } from "react";
import Header from "./Header";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

const Anti = () => {
  const [siteLoader, setSiteLoader] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    FindData();
  }, [0]);

  const data = {
    APcode: "",
    changeAPcode: "",
    Status: "",
  };
  const navigate = useNavigate();

  const [formData, setformData] = useState(data);
  const [otpPage, setotpPage] = useState(true);
  const [codePage, setcodePage] = useState(true);
  const [changeCode, setchangeCode] = useState(false);
  const [Anticode, setAnticode] = useState("");
  const [checked, setChecked] = useState(false);
  const [AntiPcode, setAntiPcode] = useState(false);
  const [changeAnticode, setchangeAnticode] = useState(false);
  const [APcodevalied, setAPcodevalied] = useState("");
  const [APcodes, setAPcodes] = useState("");
  const [OTP, setOTP] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };
  const getValue = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    // Remove whitespace from the input value
    const sanitizedValue = value.replace(/\s/g, "");
    let values = { ...formData, ...{ [name]: sanitizedValue } };
    setformData(values);
    condition(values);
  };


  const fetchOTP = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setOTP(value);
  };

  const condition = (formData) => {
    const Err = {};
    if (formData.APcode == "") {
      setAntiPcode(true);
      Err.APcode = t("anti-phising-code-required");
    } else if (formData.APcode.length < 4) {
      Err.APcode = t("minimun-4-char-only-allowed");
      setAntiPcode(true);
    } else if (formData.APcode.length > 20) {
      Err.APcode = t("minimun-20-char-only-allowed");
      setAntiPcode(true);
    } else if (!formData.APcode.match(/[!@#$%^&*(),.?":{}|<>]/g)) {
      setAntiPcode(true);
      Err.APcode = t("please-enter-at-least-one-special-char-antiphishing");
    } else {
      setAntiPcode(false);
    }

    if (formData.changeAPcode == "") {
      Err.changeAPcode = t("anti-phising-code-required");
      setchangeAnticode(true);
    } else if (formData.changeAPcode.length < 4) {
      Err.changeAPcode = t("minimun-4-char-only-allowed");
      setchangeAnticode(true);
    } else if (formData.changeAPcode.length > 20) {
      Err.changeAPcode = t("minimun-20-char-only-allowed");
      setchangeAnticode(true);
    } else if (!formData.changeAPcode.match(/[!@#$%^&*(),.?":{}|<>]/g)) {
      setchangeAnticode(true);
      Err.changeAPcode = t(
        "please-enter-at-least-one-special-char-antiphishing"
      );
    } else {
      setchangeAnticode(false);
    }
    setAPcodevalied(Err);
    return Err;
  };

  const createCode = async () => {
    if (APcodes == "") {
      condition(formData);
      if (
        formData.APcode != "" &&
        formData.APcode.length > 4 &&
        formData.APcode.length < 21 &&
        AntiPcode == false
      ) {
        formData["Status"] = checked;
        var data = {
          apiUrl: apiService.antiphishingcode,
          payload: formData,
        };
        setButtonLoading(true);
        var responce = await postMethod(data);
        setButtonLoading(false);

        if (responce.Status == true) {
          showsuccessToast(responce.Message);
          setotpPage(false);
        }
      }
    } else {
      showerrorToast(t("already-code-created"));
    }
  };

  const verifyOTP = async () => {
    if (OTP != "") {
      var obj = {
        OTP: OTP,
        changeAPcode: formData.changeAPcode,
        APcode: formData.APcode,
      };
      var data = {
        apiUrl: apiService.verificationOtp,
        payload: obj,
      };
      setButtonLoading(true);
      var responce = await postMethod(data);
      setButtonLoading(false);
      if (responce.Status == true) {
        showsuccessToast(responce.Message);
        FindData();
        navigate("/security");
      } else {
        showerrorToast(responce.Message);
      }
    } else {
      showerrorToast(t("enter-otp"));
    }
  };

  const changeAntiCode = async () => {
    condition(formData);
    if (
      formData.changeAPcode != "" &&
      formData.changeAPcode.length > 4 &&
      formData.changeAPcode.length < 21 &&
      changeAnticode == false
    ) {
      var data = {
        apiUrl: apiService.changeAntiPhising,
        payload: formData,
      };
      setButtonLoading(true);
      var responce = await postMethod(data);
      setButtonLoading(false);
      if (responce.Status == true) {
        showsuccessToast(responce.Message);
        setcodePage(false);
      } else {
        showerrorToast(responce.Message);
        setcodePage(true);
      }
    }
  };

  const FindData = async () => {
    var data = {
      apiUrl: apiService.findDetails,
      payload: formData,
    };
    setSiteLoader(true);
    var responce = await postMethod(data);
    setSiteLoader(false);
    if (responce.data != null) {
      if (responce.data.APcode != "") {
        var str = responce.data.APcode;
        setAPcodes(str);
        var replaced = str.replace(/.(?=.{4,}$)/g, "*");
        setAnticode(replaced);
        setchangeCode(true);
      } else {
        setchangeCode(false);
      }
    }
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>

      {siteLoader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#33c2b9"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <main className="anti_phishing_main">
          <div>
            <div className="Verification enable2fa-pt">
              <div className="container">
                <div>
                  <Link to="/security">
                    <h6>
                      {" "}
                      <i class="fa-solid fa-arrow-left-long mr-3"></i>{" "}
                      {t("security")}
                    </h6>
                  </Link>
                  <div className="row justify-content-center cards">
                    {changeCode == false ? (
                      <div className="col-lg-4">
                        <span class="heading">
                          {t("create-anti-phishing-code")}
                        </span>

                        {otpPage == true ? (
                          <div className="input-groups icons mt-4">
                            <h6 className="input-label">
                              {t("anti-phishing-code")}
                            </h6>
                            <input
                              type="text"
                              id="numberInput"
                              name="APcode"
                              maxLength={20}
                              onChange={getValue}
                              onKeyDown={handleKeyDown}
                              className="input-field"
                              placeholder={t("enter-antiphinng-code")}
                            />
                            {AntiPcode === true ? (
                              <p className="errorcss">
                                {" "}
                                {APcodevalied.APcode}{" "}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          <div className="input-groups icons">
                            <h6 className="input-label">
                              {t("email-verify-code")}
                            </h6>
                            <input
                              type="text"
                              id="numberInput"
                              min="0"
                              maxLength={6}
                              value={OTP}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9]/g,
                                  ""
                                ); // Allows only numbers
                              }}
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              onChange={fetchOTP}
                              className="input-field"
                              placeholder={t("enter-code")}
                            />
                          </div>
                        )}

                        <div className="Submit my-4">
                          {buttonLoading == true ? (
                            <button>{t("loading")} ...</button>
                          ) : otpPage == true ? (
                            <button onClick={createCode}>{t("confirm")}</button>
                          ) : (
                            <button onClick={verifyOTP}>
                              {t("verify-otp")}
                            </button>
                          )}
                        </div>
                        {/* <div className="foot">
                    <p>
                      Didn't receive a code ? <a>Resend</a>
                    </p>
                  </div> */}
                      </div>
                    ) : (
                      <>
                        <div className="col-lg-4">
                          <div className="input-groups icons mt-4">
                            <h6 className="input-label">
                              {t("old-anti-phishing-code")}
                            </h6>
                            <input
                              type="text"
                              value={Anticode}
                              readOnly
                              className="input-field"
                            />
                          </div>
                          {codePage == true ? (
                            <div className="input-groups icons mt-4">
                              <h6 className="input-label">
                                {t("change-anti-phishing-code")}
                              </h6>
                              <input
                                type="text"
                                name="changeAPcode"
                                maxLength={20}
                                onKeyDown={handleKeyDown}
                                onChange={getValue}
                                className="input-field"
                                placeholder={t("enter-antiphising-code")}
                              />
                              {changeAnticode == true ? (
                                <p className="errorcss">
                                  {" "}
                                  {APcodevalied.changeAPcode}{" "}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            <div className="input-groups icons">
                              <h6 className="input-label">
                                {t("email-verify-code")}
                              </h6>
                              <input
                                type="text"
                                id="numberInput"
                                min="0"
                                maxLength={4}
                                value={OTP}
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allows only numbers
                                }}
                                onChange={fetchOTP}
                                className="input-field"
                                placeholder={t("enter-code")}
                              />
                            </div>
                          )}

                          <div className="Submit my-4">
                            {codePage == true ? (
                              <button onClick={changeAntiCode}>
                                {t("change")}
                              </button>
                            ) : (
                              <button onClick={verifyOTP}>
                                {t("verify-otp")}
                              </button>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default Anti;
