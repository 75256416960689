import React, { useEffect } from "react";
import Header from "./Header";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Side_bar from "./Side_bar";
import AssetListTable from "./AssetListTable";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import Moment from "moment";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const [depositHistory, setdepositHistory] = useState([]);
  const [siteLoader, setSiteLoader] = useState(false);

  const [depositcurrentpage, setdepositcurrentpage] = useState(1);
  const [deposittotalpage, setdepositTotalpages] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    getdepositHistory(1);
  }, [0]);

  const getdepositHistory = async (page) => {
    var obj = {
      apiUrl: apiService.deposit_history,
      payload: { FilPerpage: 5, FilPage: page },
    };
    setSiteLoader(true);

    var deposit_history_list = await postMethod(obj);
    setSiteLoader(false);

    if (deposit_history_list) {
      setdepositHistory(deposit_history_list.result);
      setdepositTotalpages(deposit_history_list.total);
    }
  };

  const [toastId, setToastId] = useState(null);
  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const depositrecordpage = 5;
  const depositpagerange = 5;
  const handlepagedeposit = (event, page) => {
    getdepositHistory(page);
    setdepositcurrentpage(page);
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    showSuccessToast(t("address-copied"));
  };

  return (
    <>
      <section>
        <Header />
      </section>

      <main className="dashboard_main h-100">
        <div className="container-fluid">
          <div className="row vh-100">
            <div className="col-lg-2 col-md-0 p-0">
              <Side_bar />
            </div>

            <div className="col-lg-10 col-md-12 ">
              <div className="pad-l-r">
                <section className="asset_section pad-r-none pad-l-none">
                  <div className="row">
                    <div className="buy_head p-0">
                      <div className="Buycrypto_title">{t("history")}</div>

                      <div>
                        <ul className="history-lists">
                          <Link
                            to="/depositHistory"
                            className="history-links active"
                          >
                            {t("deposit")}
                          </Link>
                          <Link to="/withdrawHistory" className="history-links">
                            {t("withdraw")}
                          </Link>
                          <Link to="/tradeHistory" className="history-links">
                            {t("trade")}
                          </Link>
                          <Link to="/loginHistory" className="history-links">
                            {t("login")}
                          </Link>
                          <Link to="/referralHistory" className="history-links">
                            {t("referral")}
                          </Link>
                          <Link
                            to="/adminrewardTable"
                            className="history-links"
                          >
                            {t("rewards")}
                          </Link>
                          <Link to="/stakingHistory" className="history-links">
                            {t("staking")}
                          </Link>
                          <Link to="/swapHistory" className="history-links">
                            {t("swap")}
                          </Link>
                          <Link
                            to="/openorderHistory"
                            className="history-links "
                          >
                            {t("open-order-history")}
                          </Link>
                          <Link
                            to="/cancelorderHistory"
                            className="history-links "
                          >
                            {t("cancel-order-history")}
                          </Link>
                          <Link
                          to="/buycryptoHistory"
                          className="history-links "
                        >
                          {t("buy-crypto-history")}
                        </Link>
                          {/* <Link to="/stoporderHistory" className="history-links ">
                          {t("stop-order-history")}
                        </Link> */}
                        </ul>
                      </div>

                      {siteLoader == true ? (
                        <div className="loadercssss">
                          <Bars
                            height="80"
                            width="80"
                            color="#33c2b9"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        <div className="table-responsive table-cont">
                          <table className="table">
                            <thead className="ref_table_head">
                              <tr className="stake-head font-satoshi thead-bor-bottom">
                                <th>{t("date")}</th>
                                <th className="opt-nowrap txt-center pad-left-23">
                                  {t("currency")}
                                </th>
                                <th className="opt-nowrap txt-center pad-left-23">
                                  {t("amount")}
                                </th>
                                <th className="opt-nowrap txt-center pad-left-23">
                                  {t("transaction-id")}
                                </th>
                                <th className="opt-btn-flex table-action ">
                                  {t("status")}
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {depositHistory && depositHistory.length > 0 ? (
                                depositHistory.map((item, i) => {
                                  return (
                                    <tr>
                                      <td className="opt-percent font-satoshi font_14 px-2 pad-top-14px">
                                        {Moment(item.date).format(
                                          "DD.MM.YYYY hh:mm a"
                                        )}
                                      </td>
                                      <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                        {item.currencySymbol}
                                      </td>
                                      <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px">
                                        {parseFloat(item.amount).toFixed(8)}
                                      </td>
                                      <td className="opt-term font-satoshi font_14 table_center_text pad-left-23 pad-top-14px depo-transaction">
                                        {item.txnid != undefined
                                          ? item.txnid.substring(0, 10)
                                          : "-"}{" "}
                                        ...{" "}
                                        <i
                                          class="fa fa-clone"
                                          aria-hidden="true"
                                          onClick={() => copy(item.txnid)}
                                          style={{ cursor: "pointer" }}
                                        ></i>
                                      </td>
                                      <td className="opt-term font-satoshi font_14 px-2 table_center_last pad-top-14px onlycolor">
                                        {t("completed")}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={5} className="text-center py-5">
                                    <div className="empty_data">
                                      <div className="empty_data_img">
                                        <img
                                          src={require("../assets/No-data.png")}
                                          width="100px"
                                          alt="no-records"
                                        />
                                      </div>
                                      <div className="no_records_text">
                                        {t("no-records-found")}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {depositHistory && depositHistory.length > 0 ? (
                            <div className="pagination">
                              <Stack spacing={2}>
                                <Pagination
                                  count={Math.ceil(
                                    deposittotalpage / depositrecordpage
                                  )}
                                  page={depositcurrentpage}
                                  onChange={handlepagedeposit}
                                  size="small"
                                  renderItem={(item) => (
                                    <PaginationItem
                                      slots={{
                                        previous: ArrowBackIcon,
                                        next: ArrowForwardIcon,
                                      }}
                                      {...item}
                                      sx={{
                                        "&.Mui-selected": {
                                          backgroundColor: "#33c2b9",
                                          color: "#fff",
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </Stack>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Dashboard;
