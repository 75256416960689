import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { Dropdown } from "semantic-ui-react";
import Side_bar from "./Side_bar";
import "semantic-ui-css/semantic.min.css";
import { widget } from "../core/lib/chart/charting_library/charting_library.min";
import { useTranslation } from "react-i18next";
import Widget from "./widget";
import { Button } from "@material-ui/core";
import { Bars } from "react-loader-spinner";
import Moment from "moment";
import ICON from "../assets/deposit-imp.png";
import axios from "axios";

import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Dashboard = () => {
  const navigate = useNavigate();

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const [siteLoader, setSiteLoader] = useState(false);

  const [kycStatus, setkycStatus, kycStatusref] = useState();
  const [kycDetails, setkycDetails, kycDetailsref] = useState();
  const [fromCurrency, setfromCurrencyRef, fromCurrencyRef] = useState([]);
  const [toCurrency, setToCurrencyRef, toCurrencyRef] = useState([]);

  const { t } = useTranslation();

  const [selectedFromCurr, setSelectedFromCurr] = useState("ETH");
  const [selectedTOCurr, setSelectedTOCurr] = useState("EUR");
  const [walletAddress, setwalletAddress] = useState();

  const [loginCheck, setloginCheck] = useState(false);
  const [fromAmount, setAmount, fromAmountref] = useState(0);
  const [toAmount, settoAmount, toAmountref] = useState(0);
  const [selectFrom, setselectFrom, selectFromref] = useState({});
  const [conversionPrice, setconversionPrice, conversionPriceref] = useState(0);

  const [type, settype] = useState();

  useEffect(() => {
    var Type = localStorage.getItem("registe_type");
    settype(Type);
  }, [0]);

  useEffect(() => {
    let userToken = localStorage.getItem("user_token");
    getKYCstatus();
    getCurrencyList();
    if (userToken) {
      setloginCheck(true);
      get_user_detais();
      // var Type = localStorage.getItem("registe_type");
      // get_kyc_details(Type);
      // getKYCstatus();
      //   get_address();
    } else {
      setloginCheck(false);
    }
  }, []);

  const getCurrencyList = async () => {
    try {
      var data = {
        apiUrl: apiService.getCurrencyList,
      };
      setSiteLoader(true);
      var resp = await getMethod(data);
      setTimeout(() => {
        setSiteLoader(false);
      }, 800);
      const transformedFromData = [];
      resp.currency.forEach((item) => {
        transformedFromData.push({
          value: item.currencySymbol,
          key: item._id,
          text: item.currencySymbol,
          image: {
            avatar: true,
            src: item.Currency_image,
          },
          network_name: item.network_name,
          network_address: item.network_address,
        });
      });

      const transformedToData = [];
      resp.fiat.forEach((item) => {
        transformedToData.push({
          value: item.currencySymbol,
          key: item._id,
          text: item.currencySymbol,
          image: {
            avatar: true,
            src: item.Currency_image,
          },
        });
      });

      setfromCurrencyRef(transformedFromData);
      const selected = transformedFromData.find(
        (item) => item.value === selectedFromCurr
      );
      setselectFrom(selected);
      setToCurrencyRef(transformedToData);
      get_quote();
      let usertoken = localStorage.getItem("user_token");
      if (usertoken != null) {
        get_address();
      }
    } catch (err) {}
  };

  const getKYCstatus = async () => {
    let token = localStorage.getItem("user_token");
    if (token != null) {
      var data = {
        apiUrl: apiService.getKYCStatus,
      };
      var getKYC = await getMethod(data);
      //setSiteLoader(true);
      if (getKYC.status == true) {
        setkycStatus(getKYC.Message.kycstatus);
        // setTimeout(()=>{
        //   setSiteLoader(false);
        // },500)
      } else {
        //setkycStatus(0);
      }
    }
  };

  const [email, setemail, emailref] = useState("");

  const get_user_detais = async () => {
    var data = {
      apiUrl: apiService.getUserDetails,
    };
    var resp = await getMethod(data);

    localStorage.setItem("registe_type", resp.data.registerType);
    get_kyc_details(resp.data.registerType);
    setemail(resp.email);
  };

  const get_quote = async () => {
    try {
      var obj = {
        crypto: selectFromref.current.text,
        fiat: selectedTOCurr,
        network: selectFromref.current.network_name,
        amount: 100,
      };
      var data = {
        apiUrl: apiService.get_quote,
        payload: obj,
      };
      var resp = await postMethod(data);

      if (resp.status) {
        setconversionPrice(resp.price);
      }
    } catch (error) {}
  };

  const get_address = async () => {
    try {
      var obj = {
        currencySymbol: selectFromref.current.text,
        currId: selectFromref.current.key,
        network: selectFromref.current.network_address,
      };

      var data = {
        apiUrl: apiService.generateAddress,
        payload: obj,
      };

      var resp = await postMethod(data);
      if (resp.status) {
        setwalletAddress(resp.data.address);
      }
    } catch (error) {}
  };

  const handleFromCurrencyChoose = async (e, { value }) => {
    setSelectedFromCurr(value);
    const selected = fromCurrency.find((item) => item.value === value);
    setselectFrom(selected);
    var currncy_name =
      selected.text === "LINK" ||
      selected.text === "MATIC" ||
      selected.text === "USDC"
        ? "BNB"
        : selected.text;
    var currncy_key =
      selected.text === "LINK" ||
      selected.text === "MATIC" ||
      selected.text === "USDC"
        ? "66f1115e91642fcd1c976072"
        : selected.key;
    if (loginCheck == true) {
      var obj = {
        currencySymbol: currncy_name,
        currId: currncy_key,
        network: selected.network_address,
      };

      var data = {
        apiUrl: apiService.generateAddress,
        payload: obj,
      };

      var resp = await postMethod(data);
      if (resp.status) {
        setwalletAddress(resp.data.address);
      }
    }
  };
  const handleToCurrencyChoose = (e, { value }) => {
    setSelectedTOCurr(value);
  };

  var get_kyc_details = async (type) => {
    var obj = {
      type: type,
    };
    var data = {
      apiUrl: apiService.kyc_details,
      payload: obj,
    };
    var resp = await postMethod(data);

    if (resp.status) {
      var obj = {
        firstName: resp.data.firstName,
        lastName: resp.data.lastName,
        email: emailref.current,
        mobileNumber: resp.data.mobileNumber,
        dob: resp.data.dob,
        // address: {
        addressLine1: resp.data.addressLine1,
        addressLine2: resp.data.addressLine2,
        city: resp.data.city,
        state: resp.data.state,
        postCode: resp.data.postCode,
        countryCode: resp.data.countryCode,
        // },
      };
      // var obj = {
      //   firstName: 'Satoshi',
      //   lastName: 'Nakamoto',
      //   email: emailref.current,
      //   mobileNumber: '+15417543010',
      //   dob: '1994-08-26',
      //   address: {
      //     addressLine1: '170 Pine St',
      //     addressLine2: 'San Francisco',
      //     city: 'San Francisco',
      //     state: 'CA',
      //     postCode: '94111',
      //     countryCode: 'US',
      //   },
      // };
      setkycDetails(obj);
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    let sanitizedValue = value;
    if (name == "toAmount") {
      settoAmount(sanitizedValue);
      try {
        var obj = {
          crypto: selectFromref.current.text,
          fiat: selectedTOCurr,
          network: selectFromref.current.network_name,
          amount: sanitizedValue,
        };
        var data = {
          apiUrl: apiService.get_quote,
          payload: obj,
        };
        var resp = await postMethod(data);

        if (resp.status) {
          setconversionPrice(resp.price);
          setAmount(resp.crypto);
        }
      } catch (error) {}
    }
  };

  const [cryptoHistory, setcryptoHistory] = useState([]);
  const [totalPage, setTotalpages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  var cryptorecordpage = 5;
  useEffect(() => {
    cryptohistory(1);
  }, [0]);

  const cryptohistory = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page,
      };
      var data = {
        apiUrl: apiService.getbuycryptTransaction,
        payload: payload,
      };
      setSiteLoader(true);
      var resp = await postMethod(data);
      setSiteLoader(false);
      if (resp.status) {
        setcryptoHistory(resp.data.data);
        setTotalpages(resp.data.total);
      }
    } catch (error) {}
  };

  const recordPerPage = 5;

  const handlePageChange = (pageNumber) => {
    cryptohistory(pageNumber);
    setCurrentPage(pageNumber);
  };

  const [toastId, setToastId] = useState(null);
  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    showSuccessToast(t("Orderid-copied"));
  };

  return (
    <>
      <section>
        <Header />
      </section>

      {siteLoader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#33c2b9"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <main className="dashboard_main assets_main">
          <div className="container-fluid">
            <div className="row vh-100">
              {loginCheck == true ? (
                <div className="col-lg-2 col-md-0 p-0">
                  <Side_bar />
                </div>
              ) : (
                ""
              )}

              <div
                className={`${
                  loginCheck == false ? "col-lg-12" : "col-lg-10 col-md-12"
                }`}
              >
                {loginCheck == false ? (
                  <div className="pad-l-r">
                    <section className="asset_section pad-r-none pad-l-none">
                      <div className="buy_head p-0">
                        <div className="Buycrypto_title">{t("buycrpto")}</div>
                        <div
                          class="nav nav-tabs quick__nav-tabs"
                          id="nav-tab"
                          role="tablist"
                        >
                          <button
                            class="nav-link active"
                            id="nav-buy-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-buy"
                            type="button"
                            role="tab"
                            aria-controls="nav-buy"
                            aria-selected="true"
                          >
                            {t("quick-buy")}
                          </button>
                          {/* <button
                              class="nav-link "
                              id="fiat-profile-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#fiat-profile"
                              type="button"
                              role="tab"
                              aria-controls="fiat-profile"
                              aria-selected="false"
                            >
                              {t("fiat-deposits")}
                            </button> */}
                        </div>
                      </div>
                      <div class="tab-content" id="nav-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="nav-buy"
                          role="tabpanel"
                          aria-labelledby="nav-buy-tab"
                          tabindex="0"
                        >
                          <div className="row justify-content-center">
                            <div className="col-lg-6 Buy_sell">
                              <div className="swap_lft_main">
                                <div
                                  class="nav nav-tabs"
                                  id="nav-tab"
                                  role="tablist"
                                >
                                  <button
                                    class="nav-link active"
                                    id="nav-home-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-home"
                                    type="button"
                                    role="tab"
                                    aria-controls="nav-home"
                                    aria-selected="true"
                                  >
                                    {t("buy")}
                                  </button>
                                  {/* <button
                                      class="nav-link "
                                      id="nav-profile-tab"
                                      data-bs-toggle="tab"
                                      data-bs-target="#nav-profile"
                                      type="button"
                                      role="tab"
                                      aria-controls="nav-profile"
                                      aria-selected="false"
                                    >
                                      {t("sell")}
                                    </button> */}
                                </div>

                                <div class="tab-content" id="nav-tabContent">
                                  <div
                                    class="tab-pane fade show active"
                                    id="nav-home"
                                    role="tabpanel"
                                    aria-labelledby="nav-home-tab"
                                    tabindex="0"
                                  >
                                    <>
                                      <div className="swap_lft_top">
                                        <div className="foot_frst">
                                          <span className="bals_divs_insubhead">
                                            {t("pay")}
                                          </span>
                                          <input
                                            type="number"
                                            name="toAmount"
                                            min="0"
                                            placeholder="0.00"
                                            value={toAmountref.current}
                                            onChange={handleChange}
                                            className="swap_in_val dark-text-white"
                                          />
                                        </div>
                                        <div className="swap_chng_frst">
                                          <div className=" Swapcard swap_drop_all">
                                            <Dropdown
                                              placeholder={t("select-coin")}
                                              fluid
                                              selection
                                              options={toCurrencyRef.current}
                                              onChange={handleToCurrencyChoose}
                                              defaultValue={selectedTOCurr}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      {/* <div className="swap-footers">
                                        <div className="swap-footL">
                                          {" "}
                                          <span>Balance</span> : 0.00 INR
                                        </div>
                                        <div className="swap-footL"></div>
                                      </div> */}

                                      <div className="swap_mid">
                                        <img
                                          src={require("../assets/swaparrow.png")}
                                          width="24px"
                                          className="d-block mx-auto"
                                        />
                                      </div>

                                      <div className="swap_lft_top">
                                        <div className="foot_frst">
                                          <span className="bals_divs_insubhead">
                                            {t("receive")}
                                          </span>
                                          <input
                                            type="number"
                                            min="0"
                                            placeholder="0.00"
                                            value={fromAmountref.current}
                                            className="swap_in_val dark-text-white"
                                          />
                                        </div>
                                        <div className="swap_chng_frst">
                                          <div className=" Swapcard swap_drop_all">
                                            <Dropdown
                                              placeholder={t("select-coin")}
                                              fluid
                                              selection
                                              options={fromCurrencyRef.current}
                                              onChange={
                                                handleFromCurrencyChoose
                                              }
                                              defaultValue={selectedFromCurr}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="swap-footers">
                                        <div className="swap-footL">
                                          {" "}
                                          <span>{t("price")}</span>
                                        </div>
                                        <div className="swap-footL">
                                          {" "}
                                          1 {selectedFromCurr} ={" "}
                                          {parseFloat(
                                            conversionPriceref.current
                                          ).toFixed(8)}{" "}
                                          {selectedTOCurr}{" "}
                                        </div>
                                      </div>

                                      <div className="buywith_cards">
                                        {/* <button className="sells"> */}
                                        <div>
                                          <Link
                                            to="/login"
                                            className="text-white log-button"
                                          >
                                            {t("login-to-continue")}
                                          </Link>
                                        </div>
                                        {/* </button> */}
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                ) : kycStatusref.current == 1 ? (
                  <div className="pad-l-r">
                    <section className="asset_section pad-r-none pad-l-none">
                      <div className="buy_head p-0">
                        <div className="Buycrypto_title">{t("buycrpto")}</div>
                        <div
                          class="nav nav-tabs quick__nav-tabs"
                          id="nav-tab"
                          role="tablist"
                        >
                          <button
                            class="nav-link active"
                            id="nav-buy-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-buy"
                            type="button"
                            role="tab"
                            aria-controls="nav-buy"
                            aria-selected="true"
                          >
                            {t("quick-buy")}
                          </button>
                          {/* <button
                            class="nav-link "
                            id="fiat-profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#fiat-profile"
                            type="button"
                            role="tab"
                            aria-controls="fiat-profile"
                            aria-selected="false"
                          >
                            {t("fiat-deposits")}
                          </button> */}
                        </div>
                      </div>
                      <div class="tab-content" id="nav-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="nav-buy"
                          role="tabpanel"
                          aria-labelledby="nav-buy-tab"
                          tabindex="0"
                        >
                          <div className="row justify-content-center">
                            <div className="col-lg-6 Buy_sell">
                              <div className="swap_lft_main">
                                <div
                                  class="nav nav-tabs"
                                  id="nav-tab"
                                  role="tablist"
                                >
                                  <button
                                    class="nav-link active"
                                    id="nav-home-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-home"
                                    type="button"
                                    role="tab"
                                    aria-controls="nav-home"
                                    aria-selected="true"
                                  >
                                    {t("buy")}
                                  </button>
                                  {/* <button
                                    class="nav-link "
                                    id="nav-profile-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-profile"
                                    type="button"
                                    role="tab"
                                    aria-controls="nav-profile"
                                    aria-selected="false"
                                  >
                                    {t("sell")}
                                  </button> */}
                                </div>

                                <div class="tab-content" id="nav-tabContent">
                                  <div
                                    class="tab-pane fade show active"
                                    id="nav-home"
                                    role="tabpanel"
                                    aria-labelledby="nav-home-tab"
                                    tabindex="0"
                                  >
                                    <>
                                      <div className="swap_lft_top">
                                        <div className="foot_frst">
                                          <span className="bals_divs_insubhead">
                                            {t("pay")}
                                          </span>
                                          <input
                                            type="number"
                                            name="toAmount"
                                            min="0"
                                            placeholder="0.00"
                                            value={toAmountref.current}
                                            onChange={handleChange}
                                            className="swap_in_val dark-text-white"
                                          />
                                        </div>
                                        <div className="swap_chng_frst">
                                          <div className=" Swapcard swap_drop_all">
                                            <Dropdown
                                              placeholder={t("select-coin")}
                                              fluid
                                              selection
                                              options={toCurrencyRef.current}
                                              onChange={handleToCurrencyChoose}
                                              defaultValue={selectedTOCurr}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      {/* <div className="swap-footers">
                                        <div className="swap-footL">
                                          {" "}
                                          <span>Balance</span> : 0.00 INR
                                        </div>
                                        <div className="swap-footL"></div>
                                      </div> */}

                                      <div className="swap_mid">
                                        <img
                                          src={require("../assets/swaparrow.png")}
                                          width="24px"
                                          className="d-block mx-auto"
                                        />
                                      </div>

                                      <div className="swap_lft_top">
                                        <div className="foot_frst">
                                          <span className="bals_divs_insubhead">
                                            {t("receive")}
                                          </span>
                                          <input
                                            type="number"
                                            min="0"
                                            placeholder="0.00"
                                            value={fromAmountref.current}
                                            className="swap_in_val dark-text-white"
                                          />
                                        </div>
                                        <div className="swap_chng_frst">
                                          <div className=" Swapcard swap_drop_all">
                                            <Dropdown
                                              placeholder={t("select-coin")}
                                              fluid
                                              selection
                                              options={fromCurrencyRef.current}
                                              onChange={
                                                handleFromCurrencyChoose
                                              }
                                              defaultValue={selectedFromCurr}
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div className="swap-footers">
                                        <div className="swap-footL">
                                          {" "}
                                          <span>{t("price")}</span>
                                        </div>
                                        <div className="swap-footL">
                                          {" "}
                                          1 {selectedFromCurr} ={" "}
                                          {parseFloat(
                                            conversionPriceref.current
                                          ).toFixed(8)}{" "}
                                          {selectedTOCurr}{" "}
                                        </div>
                                      </div>
                                      {selectedFromCurr == "" ||
                                      selectedTOCurr == "" ? (
                                        <div className="buywith_cards">
                                          <div
                                            className="text-white log-button"
                                            aria-disabled
                                          >
                                            Buy with Card
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="buywith_cards">
                                          <Widget
                                            fromCurrency={selectedFromCurr}
                                            toCurrency={selectedTOCurr}
                                            walletAddress={walletAddress}
                                            userdata={kycDetailsref.current}
                                            fiatAmount={toAmountref.current}
                                            network={
                                              selectFromref.current.network_name
                                            }
                                          />
                                        </div>
                                      )}
                                    </>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="table-responsive table-cont dash_table_content">
                          <table className="table ">
                            <thead>
                              <tr className="stake-head depo-head font-satoshi">
                                <th className="deposit-table-date">
                                  {t("S.No")}
                                </th>
                                <th className="deposit-table-date">
                                  {t("Date")}
                                </th>
                                <th className="table_center_text">
                                  {t("Cryptocurrency")}
                                </th>
                                <th className="table_center_text">
                                  {t("Fiatcurrency")}
                                </th>
                                <th className="table_center_text">
                                  {t("CryptoAmount")}
                                </th>
                                <th className="table_center_text">
                                  {t("FiatAmount")}
                                </th>
                                <th className="table_center_text">
                                  {t("Order-id")}
                                </th>
                                <th className="table_action">
                                  {t("PaymentOption")}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {cryptoHistory && cryptoHistory.length > 0
                                ? cryptoHistory.slice(0, 5).map((item, i) => {
                                    return (
                                      <tr>
                                        <td className="px-4 opt-term font-satoshi font_14 table_center_text">
                                          {i + 1}
                                        </td>
                                        <td className="px-4 opt-term font-satoshi font_14 table_center_text">
                                          {Moment(item.createdDate).format(
                                            "lll"
                                          )}
                                        </td>
                                        <td className="px-4 opt-term font-satoshi font_14 table_center_text">
                                          {item.cryptoCurrency}
                                        </td>

                                        <td className="px-4 opt-term font-satoshi font_14 table_center_text">
                                          {item.fiatCurrency}
                                        </td>

                                        <td className="px-4 opt-term font-satoshi font_14 table_center_text">
                                          {item.fiatAmount}
                                        </td>

                                        <td className="px-4 opt-term font-satoshi font_14 table_center_text">
                                          {item.cryptoAmount}
                                        </td>
                                        <td className="px-4 opt-term font-satoshi font_14 table_center_text">
                                          {item.orderId.substring(0, 10)} ...{" "}
                                          <i
                                            class="fa fa-clone"
                                            aria-hidden="true"
                                            onClick={() => copy(item.orderId)}
                                            style={{
                                              cursor: "pointer",
                                              color: "#33c2b9",
                                            }}
                                          ></i>
                                        </td>

                                        <td className="px-4 opt-term font-satoshi font_14 table_center_text">
                                          {item.paymentOptionId}
                                        </td>
                                      </tr>
                                    );
                                  })
                                : ""}
                            </tbody>
                          </table>
                          {cryptoHistory && cryptoHistory.length > 0 ? (
                            <div className="pagination">
                              <Stack spacing={2}>
                                <Pagination
                                  count={Math.ceil(
                                    totalPage / cryptorecordpage
                                  )}
                                  page={currentPage}
                                  onChange={handlePageChange}
                                  size="small"
                                  renderItem={(item) => (
                                    <PaginationItem
                                      slots={{
                                        previous: ArrowBackIcon,
                                        next: ArrowForwardIcon,
                                      }}
                                      {...item}
                                      sx={{
                                        "&.Mui-selected": {
                                          backgroundColor: "#33c2b9",
                                          color: "#fff",
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </Stack>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        {/* <div
                          class="tab-pane fade show "
                          id="fiat-profile"
                          role="tabpanel"
                          aria-labelledby="fiat-profile-tab"
                          tabindex="0"
                        >
                          <div className="row justify-content-center">
                            <div className="col-lg-6 Buy_sell">
                              <div className="swap_lft_main">
                                <>
                                  <div className="swap-footers">
                                    <div className="swap-footL">
                                      {" "}
                                      <h4>{t("fiat-deposit")}</h4>
                                    </div>
                                    <div className="swap-footL">
                                      {" "}
                                      <a>
                                        {t("assets")}{" "}
                                        <i class="ml-3 fa-solid fa-chevron-right"></i>{" "}
                                      </a>
                                    </div>
                                  </div>
                                  <div className="swap_lft_top">
                                    <div className="foot_frst">
                                      <span className="bals_divs_insubhead">
                                        {t("deposit")}
                                      </span>
                                      <input
                                        type="number"
                                        min="0"
                                        placeholder="0.00"
                                        // value={fromAmount}
                                        // onChange={(e) =>
                                        //   setAmount(e.target.value, "fromAmount")
                                        // }
                                        className="swap_in_val dark-text-white"
                                      />
                                    </div>
                                    <div className="swap_chng_frst">
                                      <div className=" Swapcard swap_drop_all">
                                        <Dropdown
                                          placeholder="Select Coin"
                                          fluid
                                          selection
                                          // options={fromCurrencyRef.current}
                                          // onChange={(o) =>
                                          //   onSelect(o, "fromTab")
                                          // }
                                        />
                                      </div>
                                    </div>
                                  </div>
      
                                  <div className="swap-footers">
                                    <div className="swap-footL">
                                      {" "}
                                      <span> {t("min-amount")}</span> : 0.00 INR
                                    </div>
                                    <div className="swap-footL"></div>
                                  </div>
      
                                  <div className="swap_mid">
                                    <img
                                      src={require("../assets/swap.png")}
                                      width="24px"
                                      className="d-block mx-auto"
                                    />
                                  </div>
      
                                  <div className="swap_lft_top">
                                    <div className="">
                                      <span className="bals_divs_insubhead">
                                        {t("payment-method")}
                                      </span>
                                      {/* <input
                                        type="number"
                                        min="0"
                                        placeholder="0.00"
                                        // value={fromAmount}
                                        onChange={(e) =>
                                          setAmount(e.target.value, "fromAmount")
                                        }
                                        className="swap_in_val"
                                      /> */}
                        {/* <div className="clr-junc">
                                        {t("clear-junction")}
                                      </div>
                                    </div> */}
                        {/* <div className="swap_chng_frst">
                                      <div className=" Swapcard swap_drop_all">
                                        <Dropdown
                                          placeholder="Select Coin"
                                          fluid
                                          selection
                                          // options={fromCurrencyRef.current}
                                          // onChange={(o) =>
                                          //   onSelect(o, "fromTab")
                                          // }
                                          onChange={handleOnChange_from}
                                        />
                                      </div>
                                    </div> */}
                        {/* </div>
      
                                  <div className="swap-footers">
                                    <div className="swap-footL">
                                      {" "}
                                      <span>{t("fees")} </span> : 0.00 INR
                                    </div>
                                  </div>
      
                                  <div className="buywith_cards ">
                                    <Link to="/fiat_deposit">
                                      <button className="justify-content-center">
                                        <div>{t("continue")}</div>
                                      </button>
                                    </Link>
                                  </div>
                                </>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </section>
                  </div>
                ) : kycStatusref.current == 0 || kycStatusref.current == 3 ? (
                  <>
                    {/*/TODO: withdraw - KYC Verification required box*/}
                    <div className="row kycrequire">
                      <div className="col-lg-7">
                        <div className="deposit-identity-box mt-5 h-100">
                          <div className="dep-kyc">
                            <div className="dep-kyc-head">
                              <img
                                src={ICON}
                                alt="warn-icon"
                                className="deposit-imp-icon"
                              />
                              {type == "Corporates" ? (
                                <h6>{t("kyb-verification-required")}</h6>
                              ) : (
                                <h6>{t("kyc-verification-required")}</h6>
                              )}
                            </div>
                            {type == "Corporates" ? (
                              <p>
                                {t("kyb-dep-verification-required-content")}
                              </p>
                            ) : (
                              <p>{t("dep-verification-required-content")}</p>
                            )}
                            <div>
                              <img
                                src={require("../assets/deposit-id.webp")}
                                alt="Verify kyc"
                                className="before_kyc_depo withdraw-p-l-24"
                              />
                            </div>
                            <p className="mt-4">{t("please-click-button")}</p>
                            <div className="withdraw-p-l-24">
                              {type == "Corporates" ? (
                                <Link to="/kyb">
                                  <button className="mb-2 dep-kyc-btn w-100">
                                    {t("verify-now")}
                                  </button>
                                </Link>
                              ) : (
                                <Link to="/kyc">
                                  <button className="mb-2 dep-kyc-btn w-100">
                                    {t("verify-now")}
                                  </button>
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default Dashboard;
