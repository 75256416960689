import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
const Footer = () => {
  const { t } = useTranslation();
  const [loginCheck, setloginCheck] = useState(false);
  const [siteData, setsiteData] = useState(false);

  useEffect(() => {
    let userToken = localStorage.getItem("user_token");
    if (userToken) {
      setloginCheck(true);
    } else {
      setloginCheck(false);
    }
    getSiteDatas();
  }, []);

  const getSiteDatas = async ()=>{
    try{
      var data = {
        apiUrl: apiService.getSiteDatas,
      };
      var resp = await getMethod(data);
      if (resp.status == true) {
        console.log(resp.data,"respospspsps")
        setsiteData(resp.data);
      }
    }catch(err){
      console.log(err,"------")
    }
  }

  return (
    <footer className="footer_content_section">
      <div className="container bor-bottom">
        <div className="row">
          <div className="col-lg-6">
            <div className="footer_content">
              <img
                src={require("../assets/footer_logo.webp")}
                className="footer_light_logo"
              />
              <img
                src={require("../assets/fidex_dark_logo.webp")}
                className="footer_logo_dark"
              />
              <p>{t("footer-content")}</p>
            </div>
          </div>

          <div className="col-lg-6 services_content">
            <div className="services">
              <h3>{t("services")}</h3>

              <li>
                <Link to="/buycrypto">{t("buycrpto")}</Link>
              </li>
              <li>
                <Link to="/trade/ETH_USDC">{t("trade")}</Link>
              </li>
              <li>
                <Link to="/swap">{t("convert")}</Link>
              </li>

              <li>
                <Link to="/staking">{t("staking")}</Link>
              </li>
              {loginCheck == true ? (
                  <li>
                    <Link to="/deposit">{t("deposit")}</Link>
                  </li>
                ) : (
                  <li>
                    <Link to="/login">{t("deposit")}</Link>
                  </li>
              )}
              {loginCheck == true ? (

                  <li>
                    <Link to="/Withdraw">{t("withdraw")}</Link>
                  </li>
                    ) : (
                      <li>
                      <Link to="/login">{t("withdraw")}</Link>
                    </li>
                    )}
              {loginCheck == true ? (

                  <li>
                    <Link to="/referral">{t("referral")}</Link>
                  </li>
              ) : (
                <li>
                <Link to="/login">{t("referral")}</Link>
              </li>
              )}

             
            </div>
            <div className="services">
              <h3>{t("company")}</h3>
              <li>
                <Link to="">{t("about-us")}</Link>
              </li>
              <li>
                <Link to="">{t("contact-us")}</Link>
              </li>
              <li>
                <Link to="/support">{t("support")}</Link>
              </li>
              <li>
                <Link to="/">{t("Blog")}</Link>
              </li>
              {/* <li>
                <a href="">{t("work-at-fidex")}</a>
              </li> */}
            </div>
            <div className="services">
              <h3>{t("support")}</h3>
             
              <li>
                <Link to="/privacy" target="_blank">
                  {t("privacy-policy")}
                </Link>
              </li>
              <li>
                <a href="">{t("cookies-policy")}</a>
              </li>
              <li>
                <Link to="/terms" target="_blank">
                  {t("terms")}
                </Link>
              </li>
              <li>
                <a href="/">{t("Whistleblowing-Channel")}</a>
              </li>
            </div>
            <div className="services">
              <h3>{t("socials")}</h3>
              <li>
              <Link to={siteData.twitter_url} target="_blank" >
                <img src={require("../assets/twitter x.webp")} />
              </Link>
              </li>
              <li>
             <Link class="icon" to={siteData.linkedin_url} target="_blank" >
              <i class="fa-brands fa-linkedin-in fa-xl" ></i>
              </Link>
             </li>
             <li>
             <Link class="icon" to={siteData.youtube_url} target="_blank">
             <i class="fa-brands fa-youtube fa-xl"></i></Link>
             </li>
              <li>
              <Link to={siteData.fb_url} target="_blank">
                <img src={require("../assets/Facebook.webp")} />
              </Link>
              </li>
             
            
              
            </div>
          </div>
        </div>
      </div>
      <div className="copyrights">&copy; {t("copyrights")}</div>
    </footer>
  );
};

export default Footer;
