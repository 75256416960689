import React, { useEffect } from "react";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData2";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Side_bar from "./Side_bar";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import Moment from "moment";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#33c2b9" : null,
      color: isFocused ? "#fff" : "#000",
      cursor: isDisabled ? "not-allowed" : "pointer",
    };
  },
};

const Dashboard = () => {
  const [perpage, setperpage] = useState(5);
  const [page, setpage] = useState(1);
  const [search, setsearch, searchref] = useState("");
  const [balanceDetails, setbalanceDetails] = useState([]);
  const [balance_overallusdt, setbalance_overallusde] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [total, settotal] = useState(0);
  const [totalINRPrice, setToatalINRPrice] = useState(0);
  const [AvailablePrice, setAvailablePrice] = useState(0);
  const [inorderPrice, setinorderPrice] = useState(0);
  const [profileData, setprofileData] = useState("");
  const [lastLogin, setLastLogin] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [siteLoader, setSiteLoader] = useState(false);
  const [refreshStatus, setrefreshStatus] = useState(false);

  const [type, settype] = useState();

  useEffect(() => {
    var Type = localStorage.getItem("registe_type");
    settype(Type);
  }, [0]);

  const [balanceDatas, setbalanceDatas] = useState([]);
  const recordPerPage = 5;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const depositNav = () => {
    navigate("/deposit");
  };

  const withdrawNav = () => {
    navigate("/Withdraw");
  };

  const handleChange = (e) => {
    try {
      const sanitizedValue = e.target.value.replace(/\s/g, "");
      setsearch(sanitizedValue);
      searchWalletList();
    } catch (error) {}
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    var current_page = +value * 5;
    var prev_page = +current_page - 5;
    var resp_balance = [];
    for (var i = prev_page; i < current_page; i++) {
      if (balanceDatas[i] !== undefined) {
        resp_balance.push(balanceDatas[i]);
      }
    }
    setbalanceDetails(resp_balance);
  };

  const getProfile = async () => {
    try {
      var data = {
        apiUrl: apiService.getUserDetails,
      };
      setSiteLoader(true);
      var resp = await getMethod(data);
      setSiteLoader(false);

      if (resp.status == true) {
        setprofileData(resp.data);
        setLastLogin(resp.lastLogin);

        const timestamp = resp.lastLogin.createdDate;
        const date = new Date(timestamp);
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        // const timeZoneCountry = Intl.DateTimeFormat(undefined, {timeZoneName: 'long'}).formatToParts(date)
        //   .find(part => part.type === 'timeZoneName').value;
        setTimeZone(timeZone);

        localStorage.setItem("tfa_status", resp.data.tfastatus);
      }
    } catch (error) {}
  };

  const getUserTotalbalance = async (pages) => {
    var obj = {
      perpage: perpage,
      page: pages,
      search: searchref.current,
    };
    var data = {
      apiUrl: apiService.getUserTotalbalance,
      payload: obj,
    };
    setSiteLoader(true);

    var resp = await postMethod(data);
    setSiteLoader(false);

    if (resp.status == true) {
      var balanceData = resp.balance;
      setToatalINRPrice(balanceData.total_balance);
      setAvailablePrice(balanceData.available_balance);
      setinorderPrice(balanceData.inorder_balance);
    }
  };

  const [balanceLoader, setbalanceLoader] = useState();

  const [usdtBalace, setusdtBalance] = useState(0);
  const [eurBalance, setEurBalance] = useState(0);
  const [currency, setcurrency] = useState("EUR");

  // const colourStyles = (isDarkTheme) => ({
  //   option: (styles, { isDisabled, isFocused, isSelected }) => {
  //     return {
  //       ...styles,
  //       backgroundColor: isFocused
  //         ? isDarkTheme
  //           ? "#555"
  //           : "#33c2b9" // Darker background for dark theme
  //         : null, // Transparent when not focused
  //       color: isFocused ? "#fff" : isDarkTheme ? "#fff" : "#000", // White text for dark theme, black for light
  //       cursor: isDisabled ? "not-allowed" : "pointer",
  //       border: "none", // Remove border for all states
  //     };
  //   },
  //   control: (styles) => ({
  //     ...styles,
  //     border: "none", // Remove border from the control element
  //     boxShadow: "none", // Remove box shadow
  //     backgroundColor: isDarkTheme ? "#000" : "#f7f7f7", // Black for dark theme, light gray for light theme
  //     color: isDarkTheme ? "#fff" : "#000", // White text for dark theme, black for light
  //     "&:hover": {
  //       border: "none", // Remove border on hover
  //     },
  //   }),
  // });

  const choose = (data) => {
    setcurrency(data.value);
    gettotalbalnce();
  };
  const currencyOption = [
    { value: "USD", label: t("usd") },
    { value: "EUR", label: t("eur") },
  ];

  const gettotalbalnce = async () => {
    try {
      var data = {
        apiUrl: apiService.getCurrencyConvertion,
      };

      // setbalanceLoader(true);
      var resp = await getMethod(data);
      // setbalanceLoader(false);

      setusdtBalance(resp.totalUsdValue);
      setEurBalance(resp.totalEurValue);
    } catch (err) {}
  };

  const getUserbalance = async (pages) => {
    // setSiteLoader(false);
    var obj = {
      perpage: perpage,
      page: pages,
      search: searchref.current,
    };

    var data = {
      apiUrl: apiService.getUserBalance,
      payload: obj,
    };

    setbalanceLoader(true);

    var resp = await postMethod(data);
    setbalanceLoader(false);

    if (resp.status == true) {
      // setSiteLoader(false);
      var balanceData = resp.Message;
      setbalanceDatas(balanceData);

      var current_page = +resp.current * 5;
      var prev_page = +current_page - 5;
      var resp_balance = [];
      for (var i = prev_page; i < current_page; i++) {
        if (balanceData[i] !== undefined) {
          resp_balance.push(balanceData[i]);
        }
      }
      // resp_balance = resp_balance.filter(Boolean);
      setbalanceDetails(resp_balance);
      var totalnumber = resp.total;
      settotal(resp.total);
      var balanceData = resp.balance;
    } else {
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };
  const searchWalletList = async () => {
    if (
      searchref.current !== "" &&
      searchref.current !== undefined &&
      searchref.current !== null
    ) {
      const regexPattern = new RegExp(searchref.current, "i");
      const searchWallet = balanceDatas.filter((data) =>
        regexPattern.test(data.currencysymbol)
      );
      // const searchWallet = balanceDatas.filter(data => data.currencysymbol.toLowerCase() === searchref.current.toLowerCase());
      if (searchWallet.length > 0) {
        setbalanceDetails(searchWallet);
        settotal(1);
      } else {
        getUserbalance(1);
        setbalanceDetails([]);
      }
    } else {
      getUserbalance(1);
    }
  };

  const getPortfolio = async () => {
    var data = {
      apiUrl: apiService.portfolioBalance,
    };
    setSiteLoader(true);

    var resp = await getMethod(data);
    setSiteLoader(false);

    if (resp.status == true) {
      var balanceData = resp.balance;
      setToatalINRPrice(balanceData.total_balance);
      setAvailablePrice(balanceData.available_balance);
      setinorderPrice(balanceData.inorder_balance);
    } else {
    }
  };

  const [mode, setMode] = useState("");
  useEffect(() => {
    let token_socket = localStorage.getItem("user_token");
    if (!token_socket) {
      navigate("/login");
    }
    getPortfolio();
    // socket.on("sitesettings", function (res) {
    // });
    getProfile();
    FindData();
    Kycdata();
    gettotalbalnce();
    getUserbalance(currentPage);
    getUserTotalbalance(currentPage);
    var Mode = localStorage.getItem("Mode");
    setMode(Mode);
    Anti_status_check();
  }, [0]);

  const [copied, setCopied] = useState(false);
  const [changeCode, setchangeCode] = useState(false);
  const [kycStatus, setKycStatus] = useState(0);
  const [Status, setstatus] = useState("false");

  const Anti_status_check = async () => {
    var data = {
      apiUrl: apiService.Anti_status_check,
    };
    var resp = await getMethod(data);

    if (resp.status == true) {
      setstatus(resp.PhishinStatus);
    }
  };

  const FindData = async () => {
    var data = {
      apiUrl: apiService.findDetails,
    };
    setSiteLoader(true);

    var responce = await postMethod(data);
    setSiteLoader(false);

    if (responce.data != null) {
      if (responce.data.APcode != "") {
        setchangeCode(true);
      } else {
        setchangeCode(false);
      }
    } else {
      setchangeCode(false);
    }
  };

  const Kycdata = async () => {
    var data = {
      apiUrl: apiService.get_kyc_details,
    };
    var resp = await getMethod(data);
    if (resp.status) {
      setKycStatus(
        resp.data === null
          ? ""
          : resp.data.verifiedSteps == "Step-6"
          ? resp.data.kycStatus
          : 0
      );
    } else {
      setKycStatus(0);
    }
    // var data = {
    //   apiUrl: apiService.getKYC,
    // };
    // setSiteLoader(true);

    // var resp = await getMethod(data);
    // setSiteLoader(false);

    // if (resp.status) {
    //   if (resp.status != "") {
    //     var kycData = resp.datas.userDetails;
    //     setgetKYCData(kycData);
    //   }
    // }
  };

  const copy = async (text) => {
    navigator.clipboard.writeText(text);
    showSuccessToast(t("code-copied"));
  };

  const [toastId, setToastId] = useState(null);
  const showSuccessToast = (message) => {
    if (toastId) {
      toast.dismiss(toastId);
    }
    const newToastId = toast.success(message);
    setToastId(newToastId);
  };

  const [passHide, setPasshide] = useState(false);
  const [inputType, setinputType] = useState(false);

  const passwordHide = (data) => {
    if (data == "hide") {
      setPasshide(true);
      setinputType(true);
    } else {
      setPasshide(false);
      setinputType(false);
    }
  };

  const navsecurity = () => {
    navigate("/security");
  };

  return (
    <>
      <section>
        <Header />
      </section>

      <main className="dashboard_main assets_main">
        <div className="container-fluid">
          <div className="row ">
            <div className="col-lg-2 col-md-0 p-0">
              <Side_bar />
            </div>

            {siteLoader == true ? (
              <div className="loadercss">
                <Bars
                  height="80"
                  width="80"
                  color="#33c2b9"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              <div className="col-lg-10 col-md-12 ">
                <div className="pad-l-r">
                  <div className="row border-bottom pt-0">
                    <div className="col-12 p-0">
                      <div className="dashboard_content">
                        <div className="dash_user_name">
                          <h3>
                            {t("hello")}, {profileData.displayname}
                          </h3>
                          <img
                            src={require("../assets/edit_icon.png")}
                            onClick={navsecurity}
                          />
                        </div>
                        <div className="verified_low_btn">
                          {kycStatus === 1 ? (
                            <>
                              <p className="verify_success sitecolor">
                                <i class="fa-solid fa-circle-check"></i>{" "}
                                {t("verified")}
                              </p>
                            </>
                          ) : kycStatus === 2 || kycStatus === 4 ? (
                            <>
                              <p className="verify_fail">
                                <i class="ri-error-warning-fill"></i>{" "}
                                {t("pending")}
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="verify_fail">
                                <i class="ri-error-warning-fill"></i>{" "}
                                {t("not-verified")}
                              </p>
                            </>
                          )}
                          {localStorage.getItem("tfa_status") == 0 &&
                          Status == "false" ? (
                            <>
                              <p className="verify_fail">
                                <i class="ri-shield-keyhole-line"></i>{" "}
                                {t("low")}
                              </p>
                            </>
                          ) : localStorage.getItem("tfa_status") == 0 ||
                            Status == "false" ? (
                            <>
                              <p className="verify_medium">
                                <i class="ri-shield-keyhole-line"></i>{" "}
                                {t("medium")}
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="verify_success sitecolor">
                                <i class="ri-shield-keyhole-line"></i>{" "}
                                {t("high")}
                              </p>
                            </>
                          )}
                        </div>
                        <div className="user_id_card">
                          <div className="uid_content">
                            <h4>UID</h4>
                            <p>{profileData.uuid}</p>
                          </div>
                          <div className="uid_content">
                            <h4>{t("email")}</h4>
                            <p>{profileData.email}</p>
                          </div>
                          <div className="uid_content">
                            <Link to="/security">
                              <h4 className="mb-3">
                                {t("security-level")}{" "}
                                <i class="ri-arrow-right-s-line"></i>
                              </h4>
                            </Link>
                            {localStorage.getItem("tfa_status") == 0 &&
                            Status == "false" ? (
                              <>
                                <p className="verify_fail">
                                  <i class="ri-shield-keyhole-line"></i>{" "}
                                  {t("low")}
                                </p>
                              </>
                            ) : localStorage.getItem("tfa_status") == 0 ||
                              Status == "false" ? (
                              <>
                                <p className="verify_medium">
                                  <i class="ri-shield-keyhole-line"></i>{" "}
                                  {t("medium")}
                                </p>
                              </>
                            ) : (
                              <>
                                <p className="verify_success">
                                  <i class="ri-shield-keyhole-line"></i>{" "}
                                  {t("high")}
                                </p>
                              </>
                            )}
                          </div>
                          <div className="uid_content">
                            <h4>{t("sign-up-time")}</h4>
                            <p>
                              {Moment(profileData.createdDate).format("lll")}
                            </p>
                          </div>
                          <div className="uid_content">
                            <Link to="/loginHistory">
                              <h4 className="mb-3">
                                {t("last-login")}{" "}
                                <i class="ri-arrow-right-s-line"></i>
                              </h4>
                            </Link>
                            <p>{Moment(lastLogin.createdDate).format("lll")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row border-bottom">
                    <div className="col-lg-6 pad-l-none">
                      <div className="secuirty_box">
                        <div className="secuirty_box_title">
                          <h3>{t("security")}</h3>
                          {localStorage.getItem("tfa_status") == 0 &&
                          Status == "false" ? (
                            <>
                              <p className="verify_fail">
                                <i class="ri-shield-keyhole-line"></i>{" "}
                                {t("low")}
                              </p>
                            </>
                          ) : localStorage.getItem("tfa_status") == 0 ||
                            Status == "false" ? (
                            <>
                              <p className="verify_medium">
                                <i class="ri-shield-keyhole-line"></i>{" "}
                                {t("medium")}
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="verify_success">
                                <i class="ri-shield-keyhole-line"></i>{" "}
                                {t("high")}
                              </p>
                            </>
                          )}
                        </div>
                        <Link to="/security">
                          <div className="secuirty_box_content ">
                            <div className="login_asset">
                              <img src={require("../assets/login_icon.webp")} />
                            </div>
                            <div className="login_verify_content">
                              <h4>{t("verification")}</h4>
                              <p>{t("log-with-pass")}</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-6 pad-r-none">
                      <div className="secuirty_box border_none pad-r-none">
                        <div className="secuirty_box_title security-mt">
                          <h3>{t("identification")}</h3>
                          {kycStatus === 1 ? (
                            <>
                              <p className="verify_success">
                                <i class="fa-solid fa-circle-check"></i>{" "}
                                {t("verified")}
                              </p>
                            </>
                          ) : kycStatus === 2 || kycStatus === 4 ? (
                            <>
                              <p className="verify_fail">
                                <i class="ri-error-warning-fill"></i>{" "}
                                {t("pending")}
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="verify_fail">
                                <i class="ri-error-warning-fill"></i>{" "}
                                {t("not-verified")}
                              </p>
                            </>
                          )}
                        </div>

                        {type == "Corporates" ? (
                          <Link to="/kyb">
                            <div className="secuirty_box_content">
                              <div className="login_asset">
                                <img
                                  src={require("../assets/dash_id_icon.webp")}
                                />
                              </div>
                              <div className="login_verify_content">
                                <h4>
                                  {t("deposit")} / {t("withdraw")} /{" "}
                                  {t("trade-authority")}
                                </h4>
                                <p>{t("complete-the-kyb")}</p>
                              </div>
                            </div>
                          </Link>
                        ) : (
                          <Link to="/kyc">
                            <div className="secuirty_box_content">
                              <div className="login_asset">
                                <img
                                  src={require("../assets/dash_id_icon.webp")}
                                />
                              </div>
                              <div className="login_verify_content">
                                <h4>
                                  {t("deposit")} / {t("withdraw")} /{" "}
                                  {t("trade-authority")}
                                </h4>
                                <p>{t("complete-the-kyc")}</p>
                              </div>
                            </div>
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row border-bottom">
                    <div className="col-lg-6 pad-l-none">
                      <div className="secuirty_box">
                        <div className="secuirty_box_title">
                          <h3>Balance</h3>
                          <Link to="/assets">
                            <p className="bln_view">
                              {t("view")} <i class="ri-arrow-right-s-line"></i>
                            </p>
                          </Link>
                        </div>
                        <div className="secuirty_box_content2 deposit_blc_content">
                          <div className="login_verify_content">
                            <h4>{t("total-balance")}</h4>
                            {currency == "USD" ? (
                              <div className="d-flex currecydash">
                                <p className="total_balance_amt">
                                  {usdtBalace == "" ||
                                  usdtBalace == null ||
                                  usdtBalace == undefined
                                    ? 0.0
                                    : usdtBalace === "0"
                                    ? "0.00"
                                    : usdtBalace}{" "}
                                  {/* <span className="usd_text">{t("usd")}</span> */}
                                </p>
                                <Select
                                  options={currencyOption}
                                  styles={colourStyles}
                                  onChange={choose}
                                  placeholder={currencyOption[0].label}
                                  className="reg-select-dropdown currecydash2"
                                  value={currencyOption.find(
                                    (option) => option.value === currency
                                  )}
                                />
                              </div>
                            ) : (
                              <div className="d-flex currecydash">
                                <p className="total_balance_amt">
                                  {eurBalance == "" ||
                                  eurBalance == null ||
                                  eurBalance == undefined
                                    ? 0.0
                                    : eurBalance === "0"
                                    ? "0.00"
                                    : eurBalance}{" "}
                                  {/* <span className="usd_text">{t("eur")}</span> */}
                                </p>
                                <Select
                                  options={currencyOption}
                                  styles={colourStyles}
                                  onChange={choose}
                                  placeholder={currencyOption[0].label}
                                  // className="reg-select-dropdown currecydash2"
                                  className="reg-select-dropdown"
                                  value={currencyOption.find(
                                    (option) => option.value === currency
                                  )}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        {mode == "pro" ? (
                          <div className="deposit_withdraw_btns">
                            <button
                              className="deposit_btn"
                              onClick={depositNav}
                            >
                              {t("deposit")}
                            </button>

                            <button
                              className="withdraw_btn"
                              onClick={withdrawNav}
                            >
                              {t("withdraw")}
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6 pad-r-none">
                      <div className="secuirty_box border_none pad-r-none">
                        <div className="referral_sec_box">
                          <h3>{t("dash-referral")} </h3>
                          <Link to="/refferal">
                            <p className="bln_view">
                              {t("invite-now")}{" "}
                              <i class="ri-arrow-right-s-line"></i>
                            </p>
                          </Link>
                        </div>
                        <div className="invite_text">{t("invite-friends")}</div>
                        <div className="secuirty_box_content refer_content referal_box security-mt">
                          <div className="login_verify_content">
                            <h4>{t("referral-code")}</h4>
                            <p>
                              {profileData.referralCode}{" "}
                              <i
                                class="ri-file-copy-line cursor-pointer "
                                onClick={() => copy(profileData.referralCode)}
                              ></i>
                            </p>
                          </div>
                          <div className="referral_asset">
                            <img
                              src={require("../assets/referral_asset.webp")}
                            />
                          </div>
                        </div>
                        {/* <div className="share_btn">
                      <i class="ri-share-line"></i> Share
                    </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="dashboard_table">
                    <div className="staking-flex dash_assets">
                      <h5 className="opt-title">{t("assets")}</h5>
                      <div className="stake-search-container">
                        <input
                          type="text"
                          placeholder={t("search")}
                          className="stake-input"
                          onChange={handleChange}
                          onKeyDown={handleKeyDown}
                          maxLength={10}
                        />
                        <i
                          className="fa-solid fa-magnifying-glass"
                          onClick={() => searchWalletList()}
                        ></i>
                      </div>
                    </div>

                    <div className="table-responsive table-cont dash_table_content">
                      <table className="table">
                        <thead>
                          <tr className="stake-head font-satoshi">
                            <th>{t("assets")}</th>
                            <th className="table_center_text opt-nowrap txt-center pad-left-23">
                              {t("on-orders")}
                            </th>
                            <th className="table_center_text opt-nowrap txt-center pad-left-23">
                              {t("avail-balance")}
                            </th>
                            <th className="table_center_text opt-nowrap txt-center pad-left-23">
                              {t("total-balance")}
                            </th>
                            <th className="table_action tab-mar-r-10">
                              {t("action")}
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          <>
                            {balanceLoader == true ? (
                              <tr>
                                <td colSpan={5} className="text-center py-5">
                                  <div className="empty_data">
                                    <div className="empty_data_img loadercss2">
                                      <Bars
                                        height="80"
                                        width="80"
                                        color="#33c2b9"
                                        ariaLabel="bars-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                      />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ) : balanceDetails && balanceDetails.length > 0 ? (
                              balanceDetails.map((item, i) => {
                                return (
                                  <tr key={i}>
                                    <td className="table-flex">
                                      <img src={item?.currencyImage} alt="" />
                                      <div className="table-opt-name">
                                        <h4 className="opt-name font-satoshi font_14">
                                          {item?.currencysymbol}
                                        </h4>
                                        <h3 className="opt-sub font-satoshi font_14">
                                          {item?.currencyName}
                                        </h3>
                                      </div>
                                    </td>
                                    <td className="opt-term font-satoshi font_14 table_center_text pad-left-23">
                                      {parseFloat(item?.holdAmount).toFixed(4)}{" "}
                                      {item?.currencysymbol}
                                    </td>
                                    <td className="opt-term font-satoshi font_14 table_center_text pad-left-23">
                                      {parseFloat(
                                        item?.currencyBalance
                                      ).toFixed(4)}{" "}
                                      {item?.currencysymbol}
                                    </td>
                                    <td className="opt-term font-satoshi font_14 table_center_text pad-left-23">
                                      {parseFloat(
                                        item?.currencyBalance +
                                          parseFloat(item?.holdAmount)
                                      ).toFixed(4)}{" "}
                                      {item?.currencysymbol}{" "}
                                    </td>
                                    <td className="opt-btn-flex table_action pad-left-23">
                                      <Link
                                        to={`/deposit/${item?.currencysymbol}`}
                                        className="deposit_top_button"
                                      >
                                        <button className="action_btn ">
                                          {t("deposit")}
                                        </button>
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={5} className="text-center py-5">
                                  <div className="empty_data">
                                    <div className="empty_data_img">
                                      <img
                                        src={require("../assets/No-data.png")}
                                        width="100px"
                                      />
                                    </div>
                                    <div className="no_records_text">
                                      No Records Found
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </>
                        </tbody>
                      </table>
                      {balanceDetails && balanceDetails.length > 0 ? (
                        <div className="pagination">
                          <Stack spacing={2}>
                            <Pagination
                              count={Math.ceil(total / recordPerPage)}
                              page={currentPage}
                              onChange={handlePageChange}
                              size="small"
                              renderItem={(item) => (
                                <PaginationItem
                                  slots={{
                                    previous: ArrowBackIcon,
                                    next: ArrowForwardIcon,
                                  }}
                                  {...item}
                                  sx={{
                                    "&.Mui-selected": {
                                      backgroundColor: "#33c2b9",
                                      color: "#fff",
                                    },
                                  }}
                                />
                              )}
                            />
                          </Stack>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default Dashboard;
