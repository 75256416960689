import React, { useEffect, useState, useMemo } from "react";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useTranslation } from "react-i18next";

export default function FiatDeposit() {
  const [OTP, setOTP] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [counter, setCounter] = useState(60);
  const [isResendVisible, setIsResendVisible] = useState(false);
  const [value, setValue] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const { t } = useTranslation();

  const changeHandler = (value) => {
    setValue(value);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsResendVisible(true);
    }
  }, [counter]);

  const submit = async (value) => {
    try {
      if (value === "Submit") {
        var obj = {
          verifiedSteps: "Step-6",
        };
      setbuttonLoader(true);

        var data = {
          apiUrl: apiService.kybSubmition,
          payload: obj,
        };
        var resp = await postMethod(data);
      setbuttonLoader(false);
        if (resp.status == true) {
          toast.success(resp.Message);
          navigate("/dashboard");
        } else {
          toast.error(resp.Message);
        }
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
      setbuttonLoader(false);
    }
  };

  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>{" "}
      <div>
        <div className="Verification assets_main kyc_page_main information_end">
          <div className="container">
            <div className="table_padding_bottom">
              <Link to="/KybInformation5">
                <h6>
                  {" "}
                  <i class="fa-solid fa-arrow-left-long mr-3"></i> {t("back")}
                </h6>
              </Link>
              <div className="row justify-content-center cards">
                <div className="col-lg-5 kyc_information">
                  <h3 class="heading">
                    <span className="inf_no_6">6 </span>
                    {t("certification")}
                  </h3>
                  <div className="holder_checkbox_content">
                    {t("by-clicking-submit-kyb6")}
                  </div>
                  <div className="holder_checkbox_content">
                    {t("i-have-identified-all-ultimate")}
                  </div>
                  <div className="holder_checkbox_content">
                    {t("i-agree-notify-fidex-with-any-material")}
                  </div>
                  <div className="Submit next_subit_buttons my-4">
                    {buttonLoader== false ?
                                        <button
                                        className="save_button"
                                        onClick={() => submit("Safe for Later")}
                                      >
                                        {t("safe-for-later")}
                                      </button>:
                                                          <button
                                                          className="save_button"
                                                        >
                                                          {t("loading")}
                                                        </button>
                    }

                       {buttonLoader== false ?
                    <button
                      className="subit_button"
                      onClick={() => submit("Submit")}
                    >
                      {t("submit")}
                    </button>
                    :
                    <button
                    className="subit_button"
                  >
                    {t("loading")}
                  </button>
                  }
                    {/*                     
                    <button className="save_button" onClick={submit}>
                      Safe for Later
                    </button>
                    <button className="subit_button" onClick={submit}>
                      Submit
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
