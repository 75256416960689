import React, { useEffect, useMemo } from "react";
import useState from "react-usestateref";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import { postMethod, getMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import Select from "react-select";
import countryList from "react-select-country-list";
import service from "../core/service/v1";
import { useTranslation } from "react-i18next";

const colourStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#33c2b9" : null,
      color: isFocused ? "#fff" : "#000",
      cursor: isDisabled ? "not-allowed" : "pointer",
    };
  },
};

export default function FiatDeposit() {
  const [OTP, setOTP] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [counter, setCounter] = useState(60);
  const [isResendVisible, setIsResendVisible] = useState(false);
  const [value, setValue] = useState("");

  const [selectBtn, setSelectBtn] = useState("yes");
  const [selectNoBtn, setSelectNoBtn] = useState("no");
  const { t } = useTranslation();

  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setValue(value);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsResendVisible(true);
    }
  }, [counter]);

  // CHANGE BUTTON COLORS YES-NO
  const yesBtnClick = () => {
    setSelectBtn("yes");
  };
  const noBtnClick = () => {
    setSelectBtn("no");
  };

  // CHANGE BUTTON COLORS NO-YES
  const yesClick = () => {
    setSelectNoBtn("yes");
  };
  const noClick = () => {
    setSelectNoBtn("no");
  };

  // FUNCTION AUTHOR JOTHI================

  const Industries = [
    { value: "Law/Notary", label: t("law/notary") },
    { value: "Art/Antiques", label: t("art-ntiques") },
    { value: "Public Company", label: t("public-company") },
    { value: "Currency Exchange Office", label: t("currency-exchange-office") },
    { value: "Casino/Betting", label: t("casino-betting") },
    {
      value: "Construction/Real Estate Development",
      label: "Construction/Real Estate Development",
    },
    { value: "Wealth Management", label: t("wealth-manage") },
    { value: "Information Technology", label: t("info-tech") },
    { value: "Precious Metals", label: t("precious-metals") },
    { value: "Import-Export", label: t("import-export") },
    { value: "Sports Company", label: t("sports-company") },
    {
      value: "Holding/Instrumental Company",
      label: t("holding-company"),
    },
    { value: "Others", label: t("others") },
  ];

  const initialFormValue = {
    legalEntityName: "",
    alternateName: "",
    bussinessAddress: "",
    bussinessunit: "",
    bussinesscity: "",
    bussinesspostalCode: "",
    bussinescountry: "",
    validAddressAuthority: "",
    bussinesWebsite: "",
    bussinesIndustry: "",
    bussinesIndustryother:"",
    service: "",
    businessconsidrationstatus: "",
  };

  const [formValue, setFormValue] = useState(initialFormValue);
  const [legalnameErrMsg, setlegalnameErrMsg, legalnameErrMsgref] =
    useState("");
  const [legalNamevallidate, setlegalNamevallidate, legalNamevallidateref] =
    useState(false);
  const [AlternateNameErrMsg, setAlternateNameErrMsg, AlternateNameErrMsgref] =
    useState("");
  const [
    AlternateNameValidate,
    setAlternateNameValidate,
    AlternateNameValidateref,
  ] = useState(false);
  const [
    BusinessAddressErrMsg,
    setBusinessAddressErrMsg,
    BusinessAddressErrMsgref,
  ] = useState("");
  const [
    BusinessAddressValidate,
    setBusinessAddressValidate,
    BusinessAddressValidateref,
  ] = useState(false);
  const [BusinessCityErrMsg, setBusinessCityErrMsg, BusinessCityErrMsgref] =
    useState("");
  const [
    BusinessCityValidate,
    setBusinessCityValidate,
    BusinessCityValidateref,
  ] = useState(false);
  const [PostalCodeErrMsg, setPostalCodeErrMsg, PostalCodeErrMsgref] =
    useState("");
  const [PostalCodeValidate, setPostalCodeValidate, PostalCodeValidateref] =
    useState(false);
  const [WebsiteErrMsg, setWebsiteErrMsg, WebsiteErrMsgref] = useState("");
  const [WebsiteValidate, setWebsiteValidate, WebsiteValidateref] =
    useState(false);
  const [ServiceErrMsg, setServiceErrMsg, ServiceErrMsgref] = useState("");
  const [ServiceValidate, setServiceValidate, ServiceValidateref] =
    useState(false);
  const [IndustryErrMsg, setIndustryErrMsg, IndustryErrMsgref] = useState("");
  const [IndustryotherErrMsg, setIndustryotherErrMsg, IndustryotherErrMsgref] = useState("");
  const [IndustryValidate, setIndustryValidate, IndustryValidateref] =
    useState(false);
    const [IndustryotherValidate, setIndustryotherValidate, IndustryotherValidateref] =
    useState(false);
  const [countryErrMsg, setcountryErrMsg, countryErrMsgref] = useState("");
  const [countryValidate, setcountryValidate, countryValidateref] =
    useState(false);
  const [bussinessunitErrMsg, setBussinessunitErrMsg, bussinessunitErrMsgref] = useState("");
  const [bussinessunitValidate, setBussinessunitValidate, bussinessunitValidateref] = useState(false);
  const {
    legalEntityName,
    alternateName,
    bussinessAddress,
    bussinessunit,
    bussinesscity,
    bussinesspostalCode,
    bussinescountry,
    validAddressAuthority,
    bussinesWebsite,
    bussinesIndustryother,
    service,
    bussinesIndustry,
    businessconsidrationstatus,
  } = formValue;

  useEffect(() => {
    getStep();
  }, [0]);

  const handleChange = async (e) => {
    try {
      const { name, value } = e.target;
      // let sanitizedValue = value.replace(/\s/g, "");
      let formData = { ...formValue, ...{ [name]: value } };
      setFormValue(formData); // Update form value
      validation_2(formData); // Pass updated formData to validation
    } catch (err) {
    }
  };
  

  const handleKeyDown = (e) => {
    if (e.key === ' ' && e.target.selectionStart === 0) {
      e.preventDefault();
      }
  };


  const [touched, setTouched] = useState({
    legalEntityName: false,
    alternateName: false,
    bussinessAddress: false,
    bussinesscity: false,
    bussinesspostalCode: false,
    bussinescountry: false,
    bussinesWebsite: false,
    bussinesIndustryother:false,
    service: false,
    bussinesIndustry: false,
  });

  const handleBlur = (field) => {
    setTouched({ ...touched, [field]: true });
    validation_2(formValue); // Call validation on blur
  };


  const handleCountryChange = (selectedCountry) => {
    const updatedFormValue = {
      ...formValue,
      bussinescountry: selectedCountry.value,
    };
    setFormValue(updatedFormValue);
    validation_2(updatedFormValue);
  };

  const handleIndustryChange = (selectedIndustry) => {
    const updatedFormValue = {
      ...formValue,
      bussinesIndustry: selectedIndustry ? selectedIndustry.value : "",
    };
    setFormValue(updatedFormValue);
    validation_2(updatedFormValue);
  };

  const validation_2 = (formValue) => {

  // Initialize a flag to check if there are any errors
  let hasError = false;

  // Reset all error messages and validation states
  setlegalnameErrMsg("");
  setlegalNamevallidate(false);
  setAlternateNameErrMsg("");
  setAlternateNameValidate(false);
  setBusinessAddressErrMsg("");
  setBusinessAddressValidate(false);
  setBussinessunitErrMsg("");
  setBussinessunitValidate(false);
  setBusinessCityErrMsg("");
  setBusinessCityValidate(false);
  setPostalCodeErrMsg("");
  setPostalCodeValidate(false);
  setcountryErrMsg("");
  setcountryValidate(false);
  setWebsiteErrMsg("");
  setWebsiteValidate(false);
  setIndustryErrMsg("");
  setIndustryValidate(false);
  setIndustryotherValidate(false)
  setServiceErrMsg("");
  setServiceValidate(false);

  try {
    // Legal Entity Name validation
    if (formValue.legalEntityName === "") {
      setlegalnameErrMsg(t("legal-entity-name-required"));
      setlegalNamevallidate(true);
      hasError = true;
    } else if (!/^[a-zA-Z]/.test(formValue.legalEntityName)) {
      setlegalnameErrMsg(t("legal-entity-name-must-start-letter"));
      setlegalNamevallidate(true);
      hasError = true;
    } else if (
      formValue.legalEntityName.length < 3 ||
      formValue.legalEntityName.length > 25
    ) {
      setlegalnameErrMsg(t("legal-entity-name-b/w-3-25-char"));
      setlegalNamevallidate(true);
      hasError = true;
    } else if (!/^[a-zA-Z0-9 ]+$/.test(formValue.legalEntityName)) {
      setlegalnameErrMsg(t("legal-entity-name-contain-letters-only"));
      setlegalNamevallidate(true);
      hasError = true;
    }


    // Alternate Name validation
    if (!hasError) {
      if (formValue.alternateName === "") {
        setAlternateNameErrMsg(t("alternate-name-required"));
        setAlternateNameValidate(true);
        hasError = true;
      } else if (!/^[a-zA-Z]/.test(formValue.alternateName)) {
        setAlternateNameErrMsg(t("alternate-name-must-start-letter"));
        setAlternateNameValidate(true);
        hasError = true;
      } else if (
        formValue.alternateName.length < 3 ||
        formValue.alternateName.length > 25
      ) {
        setAlternateNameErrMsg(t("alternate-name-between-3-25-char"));
        setAlternateNameValidate(true);
        hasError = true;
      } else if (!/^[a-zA-Z0-9 ]+$/.test(formValue.alternateName)) {
        setAlternateNameErrMsg(
          t("legal-entity-name-contain-letters-only")
        );
        setAlternateNameValidate(true);
        hasError = true;
      }
    }

    // Business Address validation
    if (!hasError) {
      if (formValue.bussinessAddress === "") {
        setBusinessAddressErrMsg(t("business-address-required"));
        setBusinessAddressValidate(true);
        hasError = true;
      } else if (
        formValue.bussinessAddress.length < 3 ||
        formValue.bussinessAddress.length > 250
      ) {
        setBusinessAddressErrMsg(t("business-address-length-3-250"));
        setBusinessAddressValidate(true);
        hasError = true;
      }
    }

    // Business Unit validation
    // if (!hasError && formValue.bussinessunit !== "") {
    //   if (
    //     formValue.bussinessunit.length < 3 ||
    //     formValue.bussinessunit.length > 6
    //   ) {
    //     setBussinessunitErrMsg(t("unit-number-length-3-6"));
    //     setBussinessunitValidate(true);
    //     hasError = true;
    //   }
    // }

    // Business City validation
    if (!hasError) {
      if (formValue.bussinesscity === "") {
        setBusinessCityErrMsg(t("city-required-field"));
        setBusinessCityValidate(true);
        hasError = true;
      }
    }

    // Postal Code validation
    if (!hasError) {
      if (formValue.bussinesspostalCode === "") {
        setPostalCodeErrMsg(t("postal-code-required"));
        setPostalCodeValidate(true);
        hasError = true;
      } else if (!/^[0-9]{5,10}$/.test(formValue.bussinesspostalCode)) {
        setPostalCodeErrMsg(t("postal-code-between-5-10-digits"));
        setPostalCodeValidate(true);
        hasError = true;
      }
    }

    // Country validation
    if (!hasError) {
      if (formValue.bussinescountry === "") {
        setcountryErrMsg(t("country-required-field"));
        setcountryValidate(true);
        hasError = true;
      }
    }

    // Business Website validation
    if (!hasError) {
      if (formValue.bussinesWebsite.trim() === "") {
        setWebsiteErrMsg(t("business-website-required"));
        setWebsiteValidate(true);
        hasError = true;
      } else if (
        formValue.bussinesWebsite.toLowerCase() !== "n/a" &&
        formValue.bussinesWebsite.toLowerCase() !== "na"
      ) {
        if (
          formValue.bussinesWebsite.length < 1 ||
          formValue.bussinesWebsite.length > 2048
        ) {
          setWebsiteErrMsg(t("website-length-1-2048"));
          setWebsiteValidate(true);
          hasError = true;
        } else if (
          !/^(https?:\/\/)?([\w\d-]+\.)+\w{2,}(\/[\w\d-]*)*\/?$/.test(
            formValue.bussinesWebsite.trim()
          )
        ) {
          setWebsiteErrMsg(t("enter-valid-website-url"));
          setWebsiteValidate(true);
          hasError = true;
        }
      }
    }

    // Industry validation
    if (!hasError) {
      if (formValue.bussinesIndustry === "") {
        setIndustryErrMsg(t("industry-required"));
        setIndustryValidate(true);
        hasError = true;
      } else if (formValue.bussinesIndustry === "Others" && formValue.bussinesIndustryother=="") {
        setIndustryErrMsg(t("specify-industry-required"));
        setIndustryValidate(true);
        hasError = true;
      }
    }

    // Service validation
    if (!hasError) {
      if (formValue.service === "") {
        setServiceErrMsg(t("service-required"));
        setServiceValidate(true);
        hasError = true;
      } else if (
        formValue.service.length < 3 ||
        formValue.service.length > 150
      ) {
        setServiceErrMsg(t("service-length-3-150"));
        setServiceValidate(true);
        hasError = true;
      }
    }

    // If no errors, reset all error messages and validation states
    if (!hasError) {
      setServiceValidate(false);
      setServiceErrMsg("");
      setIndustryValidate(false);
      setIndustryErrMsg("");
      setWebsiteValidate(false);
      setWebsiteErrMsg("");
      setcountryValidate(false);
      setcountryErrMsg("");
      setPostalCodeValidate(false);
      setPostalCodeErrMsg("");
      setBusinessCityValidate(false);
      setIndustryotherValidate(false)
      setBusinessCityErrMsg("");
      setBusinessAddressValidate(false);
      setBusinessAddressErrMsg("");
      setAlternateNameValidate(false);
      setAlternateNameErrMsg("");
      setlegalNamevallidate(false);
      setlegalnameErrMsg("");
    }
  } catch (err) {
  }
};

  
  
  const submit = async (e) => {
    try {
      e.preventDefault();
      validation_2(formValue);
      if (
        !legalNamevallidateref.current &&
        !AlternateNameValidateref.current &&
        !BusinessAddressValidateref.current &&
        !BusinessCityValidateref.current &&
        !PostalCodeValidateref.current &&
        !WebsiteValidateref.current &&
        !IndustryValidateref.current &&
        !ServiceValidateref.current &&
        !countryValidateref.current &&
        formValue.service !==""
      ) {
        formValue["verifiedSteps"] = "Step-1";
        formValue["validAddressAuthority"] = selectBtn;
        formValue["businessconsidrationstatus"] = selectNoBtn;
        var data = {
          apiUrl: apiService.kybfirst,
          payload: formValue,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setbuttonLoader(false);
        if (resp.status == true) {
          toast.success(resp.Message);
          navigate("/KybInformation2");
        } else {
          toast.error(resp.Message);
        }
      } else {
        validation_2(formValue);
      }
    } catch (err) {
    }
  };

  const getStep = async () => {
    try {
      var data = {
        apiUrl: apiService.get_kyb_details,
      };
      var resp = await getMethod(data);
      if (resp.data !== null) {
        var kybData = resp.data;
        formValue.legalEntityName = kybData.legalEntityName;
        formValue.alternateName = kybData.alternateName;
        formValue.bussinessAddress = kybData.bussinessAddress;
        formValue.bussinessunit = kybData.bussinessunit;
        formValue.bussinesscity = kybData.bussinesscity;
        formValue.bussinesspostalCode = kybData.bussinesspostalCode;
        formValue.bussinescountry = kybData.bussinescountry;
        formValue.validAddressAuthority = kybData.validAddressAuthority;
        formValue.bussinesWebsite = kybData.bussinesWebsite;
        formValue.service = kybData.service;
        formValue.bussinesIndustry = kybData.bussinesIndustry;
        formValue.businessconsidrationstatus =
          kybData.businessconsidrationstatus;
      }
    } catch (err) { }
  };

  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>{" "}
      <div>
        <div className="Verification assets_main kyc_page_main">
          <div className="container">
            <div className="table_padding_bottom">
              <Link to="/buycrypto">
                <h6>
                  {" "}
                  <i class="fa-solid fa-arrow-left-long mr-3"></i>{" "}
                  {t("verification")}
                </h6>
              </Link>
              <div className="row justify-content-center cards">
                <div className="col-lg-5 kyc_information">
                  <h3 class="heading">
                    <span className="inf_no">1</span>
                    {t("business-information")}
                  </h3>
                  <div className="input-groups fiat_deposit_name_details">
                    <div className="fiat_deposit_detail legal_name">
                      <h6 className="input-label">{t("legal-entity-name")}</h6>
                      <div className="input-field ref_input">
                        <input
                          type="text"
                          name="legalEntityName"
                          minLength={3}
                          maxLength={25}
                          value={legalEntityName}
                          onKeyDown={handleKeyDown}
                          onBlur={() => handleBlur("legalEntityName")}
                          onChange={handleChange}
                          className=""
                          placeholder={t("enter-legal-entity-name")}
                        />
                      </div>
                      {legalNamevallidateref.current ==true ? (
                        <p className="errorcss">{legalnameErrMsgref.current}</p>
                      ):""}
                    </div>
                  </div>
                  <div className="input-groups fiat_deposit_name_details">
                    <div className="fiat_deposit_detail legal_name">
                      <h6 className="input-label">{t("alternate-name")}</h6>
                      <div className="input-field ref_input">
                        <input
                          type="text"
                          name="alternateName"
                          value={alternateName}
                          onKeyDown={handleKeyDown}
                          minLength={3}
                          maxLength={25}
                          onBlur={() => handleBlur("alternateName")}
                          onChange={handleChange}
                          className=""
                          placeholder={t("alternate-name-placeholder")}
                        />
                      </div>
                      {AlternateNameValidateref.current && (
                        <p className="errorcss">
                          {AlternateNameErrMsgref.current}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="input-groups fiat_deposit_name_details">
                  <div className="fiat_deposit_detail">
                      <h6 className="input-label"> {t("registered-business-address")}</h6>
                      <div className="input-field ref_input">
                        <input
                          type="text"
                          name="bussinessAddress"
                          value={bussinessAddress}
                          onKeyDown={handleKeyDown}
                          minLength={3}
                          maxLength={250}
                          onBlur={() => handleBlur("bussinessAddress")}
                          onChange={handleChange}
                          placeholder={t("enter-bussinessAddress")}
                        />
                      </div>
                      {BusinessAddressValidateref.current && (
                        <p className="errorcss">
                          {BusinessAddressErrMsgref.current}
                        </p>
                      )}
                    </div>
                    <div className="fiat_deposit_detail">
                    <h6 className="input-label">{t("unit#(optional)")}</h6>
                    <div className="input-field ref_input">
                        <input
                          type="text"
                          id="numberInput"
                          min="3"
                          maxLength={6}
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          // onInput={(e) => {
                          //   e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allows only numbers
                          // }}
                          name="bussinessunit"
                          value={formValue.bussinessunit}
                          onBlur={() => handleBlur("bussinessunit")}
                          onChange={handleChange}
                          className=""
                          placeholder={t("unit#")}
                        />
                      </div>
                      {bussinessunitValidateref.current && (
                        <p className="errorcss">
                          {bussinessunitErrMsgref.current}
                        </p>
                      )}
                    </div>
                    </div>
                    <div className="input-groups fiat_deposit_name_details">
                    <div className="fiat_deposit_detail legal_name">  
                  <h6 className="input-label">{t("city")}</h6>
                  <div className="input-field ref_input">
                        <input
                          type="text"
                          name="bussinesscity"
                          value={bussinesscity}
                          onKeyDown={handleKeyDown}
                          minLength={3}
                          maxLength={25}
                          onBlur={() => handleBlur("bussinesscity")}
                          onChange={handleChange}
                          className=""
                          placeholder="Spain"
                        />
                      </div>
                      {BusinessCityValidateref.current === true  ?(
                        <p className="errorcss">
                          {BusinessCityErrMsgref.current}
                        </p>
                      ):""}
                    </div>
</div>
                    <div className="input-groups fiat_deposit_name_details">
                    <div className="fiat_deposit_detail">
                    <h6 className="input-label">{t("zip-postal-code")}</h6>
                    <div className="input-field ref_input">
                        <input
                         type="text"
                         id="numberInput"
                         min="5"
                         maxLength={10}
                         onKeyDown={(evt) =>
                           ["e", "E", "+", "-"].includes(evt.key) &&
                           evt.preventDefault()
                         }
                         onInput={(e) => {
                           e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allows only numbers
                         }}
                        name="bussinesspostalCode"
                        value={bussinesspostalCode}
                        onBlur={() => handleBlur("bussinesspostalCode")}
                        onChange={handleChange}
                        className=""
                        
                        placeholder={t("enter-zip-postal-code")}
                        />
                      </div>
                      {PostalCodeValidateref.current ===true ? (
                            <p className="errorcss">
                              {PostalCodeErrMsgref.current}
                            </p>
                          ):""}
                    </div>
                    <div className="fiat_deposit_detail">
                  <h6 className="input-label">{t("country")}</h6>
                          <div className="custom-select-stake kyc-verify selectclass">
                            <div className="input-groups select-wrapper ">
                              <Select
                                options={options}
                                value={options.find(
                                  (option) => option.value === bussinescountry
                                )}
                                onChange={handleCountryChange}
                                placeholder={t("select-country")}
                                styles={colourStyles}
                                className="reg-select-dropdown "
                              />
                            </div>
                          </div>
                          {/* <div className="input-field ref_input kyc_country_dropdown"></div> */}
                          {countryValidateref.current ==true ? (
                            <p className="errorcss">
                              {countryErrMsgref.current}
                            </p>
                          ) : ""}
                        </div>
                 
                    </div>

                 

                  <div className="access_act_text">
                    <h3>
                      {t(
                        "is-this-address-where-your-company-physically-conducts"
                      )}
                    </h3>
                    <div className="access_act_text_btn mt-4">
                      <button
                        onClick={yesBtnClick}
                        className={
                          selectBtn === "yes" ? "yes_button" : "no_button"
                        }
                      >
                        {t("yes")}
                      </button>
                      <button
                        onClick={noBtnClick}
                        className={
                          selectBtn === "no" ? "yes_button" : "no_button"
                        }
                      >
                        {t("no")}
                      </button>
                    </div>
                  </div>
                  <div className="input-groups fiat_deposit_name_details">
                    <div className="fiat_deposit_detail legal_name">
                      <h6 className="input-label">{t("business-website")}</h6>
                      <div className="input-field ref_input">
                        <input
                          type="text"
                          name="bussinesWebsite"
                          value={bussinesWebsite}
                          onKeyDown={handleKeyDown}
                          minLength={3}
                          maxLength={250}
                          onBlur={() => handleBlur("bussinesWebsite")}
                          onChange={handleChange}
                          placeholder={t("business-website-link")}
                        />
                      </div>
                      {WebsiteValidateref.current && (
                        <p className="errorcss">{WebsiteErrMsgref.current}</p>
                      )}

                      <span className="text_website">
                        {t("if-no-website-put-n/a")}
                      </span>
                    </div>
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label">{t("industry")}</h6>
                    <div className="custom-select-stake kyc-verify my-2 w-100">
                      <div className="input-groups select-wrapper ">
                        <Select
                          options={Industries}
                          value={Industries.find(
                            (option) => option.value === bussinesIndustry
                          )}
                          onChange={handleIndustryChange}
                          placeholder={t("select-industry")}
                          styles={colourStyles}
                          className="reg-select-dropdown "
                        />
                      </div>
                    </div>
                    {/* <div className="input-field ref_input upload_icon kyc_country_dropdown"></div> */}
                 
                  </div>

                  {formValue.bussinesIndustry=="Others" && (
                      <div className="input-groups icons mt-3">
                        <h6 className="input-label">
                          {t("please-specify-Industry")}
                        </h6>
                        <div className="input-field ref_input upload_icon">
                          <input
                            type="text"
                            className=""
                            name="bussinesIndustryother"
                            value={bussinesIndustryother}
                            minLength={3}
                            maxLength={50}
                            onKeyDown={handleKeyDown}
                            onBlur={() => handleBlur("bussinesIndustryother")}
                            onChange={handleChange}
                            placeholder={t("please-specify-Industry")}
                          />
                        </div>
                      </div>
                    )}
                       {IndustryValidateref.current && (
                      <p className="errorcss">{IndustryErrMsgref.current}</p>
                    )}
                  {/* {IndustryotherValidateref.current && (
                      <p className="errorcss">{IndustryotherErrMsgref.current}</p>
                    )} */}
                    
                  <div className="input-groups fiat_deposit_name_details">
                    <div className="fiat_deposit_detail legal_name">
                      <h6 className="input-label">{t("goods-services")}</h6>
                      <div className="input-field ref_input">
                        <input
                          type="text"
                          name="service"
                          value={service}
                          minLength={3}
                          onKeyDown={handleKeyDown}
                          maxLength={150}
                          onBlur={() => handleBlur("service")}
                          onChange={handleChange}
                          placeholder={t("goods-services-placeholder")}
                        />
                      </div>
                      {ServiceValidateref.current && (
                        <p className="errorcss">{ServiceErrMsgref.current}</p>
                      )}
                    </div>
                  </div>
                  <div className="access_act_text">
                    <h3>
                      {t("is-your-business-considered-financial-institution")}
                    </h3>
                    <div className="access_act_text_btn mt-4">
                      <button
                        onClick={yesClick}
                        className={
                          selectNoBtn === "no" ? "no_button" : "yes_button"
                        }
                      >
                        {t("yes")}
                      </button>
                      <button
                        onClick={noClick}
                        className={
                          selectNoBtn === "yes" ? "no_button" : "yes_button"
                        }
                      >
                        {t("no")}
                      </button>
                    </div>
                  </div>
                  <div className="Submit my-4">
                    {buttonLoader ==true ?
                    <button >{t("loading")}...</button>
                    :
                    <button onClick={submit}>{t("next")}</button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
