import { Link } from "react-router-dom";
import Header from "./Header";

const UnderMaintanence = () => {
  return (
    <>
      <div className="page-head-none">
        <Header />
      </div>

      <div className="page-not-found">
        <div className="not-found-img-wrap">
          <img
            src={require("../assets/maintanence.webp")}
            alt="page-not-found"
            className="page-img-light"
          />
          <img
            src={require("../assets/maintanence-dark.webp")}
            alt="page-not-found"
            className="page-img-dark"
          />
        </div>
        <h3 className="not-found-title">This Page is Under Maintenance</h3>
        <p className="not-found-content">
          We’re currently working on some improvements. Please check back{" "}
          <div className="page-inline">soon. Thank you for your patience!</div>
        </p>
        {/* <Link to="/" className="not-found-btn">
          Go Back
        </Link> */}
      </div>
    </>
  );
};

export default UnderMaintanence;
