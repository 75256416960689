import React, { useState, useEffect } from "react";
import Header from "./Header";
import "react-phone-input-2/lib/style.css";
import Side_bar from "./Side_bar";
import { Link } from "react-router-dom";

import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { useTranslation } from "react-i18next";

const Kyb = () => {
  const { t } = useTranslation();

  useEffect(() => {
    get_kyc_datails();
  }, []);

  const [kycStatus, setKycStatus] = useState("");
  const [rejectReason, setrejectReason] = useState("");

  const get_kyc_datails = async () => {
    try {
      var data = {
        apiUrl: apiService.get_kyb_details,
      };
      var resp = await getMethod(data);
      setKycStatus(resp.data === null ? "" : resp.data.kycStatus);
      setrejectReason(resp.data === null ? "" : resp.data.rejectReason);
    } catch (err) {}
  };

  return (
    <>
      <section>
        <Header />
      </section>

      <main className="Kyb_main dashboard_main assets_main ">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-2 col-md-0 p-0">
              <Side_bar />
            </div>

            <div className="col-lg-10 col-md-12">
              <div className="pad-l-r">
                <section className="asset_section pad-r-none pad-l-none">
                  <div className="row">
                    <div className="identification_title pad-l-none">
                      {t("identification")}
                    </div>

                    <div className="col-lg-7 pad-l-none">
                      {kycStatus == 3 ? (
                        <div className="standard_verify_box">
                          <div className="standard_verify_content">
                            <h3>{t("reject-reason")} :</h3>
                            <h5 className="text-danger">
                              {rejectReason ? rejectReason : "Not Valid"}{" "}
                            </h5>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="standard_verify_box">
                        <div className="standard_verify_content">
                          <h3>{t("standard-id-verify")}</h3>
                          <p className="font-satoshi">
                            {t("standard-id-verify-content")}
                          </p>
                          {[0, 2].includes(kycStatus) ? (
                            <button
                              className="verify_btn opt-nowrap"
                              type="button"
                            >
                              <Link className="text-white" to="/kybProcess">
                                {t("verify-now")}
                              </Link>
                            </button>
                          ) : [4].includes(kycStatus) ? (
                            <button
                              className="verify_btn opt-nowrap"
                              type="button"
                            >
                              <Link className="text-white">{t("pending")}</Link>
                            </button>
                          ) : [1].includes(kycStatus) ? (
                            <button
                              className="verify_btn opt-nowrap"
                              type="button"
                            >
                              <Link className="text-white">
                                {t("verified")}
                              </Link>
                            </button>
                          ) : [3].includes(kycStatus) ? (
                            <button
                              className="verify_btn opt-nowrap"
                              type="button"
                            >
                              <Link className="text-white" to="/kybProcess">
                                {t("verify-now")}
                              </Link>
                            </button>
                          ) : (
                            <button
                              className="verify_btn opt-nowrap"
                              type="button"
                            >
                              <Link className="text-white" to="/kybProcess">
                                {t("verify-now")}
                              </Link>
                            </button>
                          )}
                        </div>
                        <div className="standard_verify_img">
                          <img
                            src={require("../assets/standard_verify_img.png")}
                          />
                        </div>
                      </div>
                      <div className="individual_kyc_box">
                        <div className="individual_title">
                          {t("corporates-kyb")}
                        </div>
                        <div className="identification_features">
                          <div className="features">
                            <h3 className="font-satoshi">{t("features")}</h3>
                            <p>{t("withdraw")}</p>
                            <p>{t("trading")}</p>
                            <p>
                              {t("deposits")}/{t("crypto-purchases")}
                            </p>
                            <p>{t("others")}</p>
                          </div>
                          <div className="features unverified">
                            <h3 className="font-satoshi">{t("un-verified")}</h3>
                            <p>0 USDC</p>
                            <p>-</p>
                            <p>-</p>
                            <p>-</p>
                          </div>
                          <div className="features unverified">
                            <h3 className="font-satoshi">{t("verified")}</h3>
                            <p className="text_green">999,999 USDC</p>
                            <p className="text_green">
                              <i class="ri-check-line"></i>
                            </p>
                            <p className="text_green">
                              <i class="ri-check-line"></i>
                            </p>
                            <p className="text_green">
                              {t("more-event-bonus")}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="standard_verify_box">
                        <div className="standard_verify_content">
                          <h3>Requirements</h3>
                          <p className="font-satoshi standard_verify_content-flex">
                            <img src={require("../assets/Id_doc_icon.png")} />{" "}
                            {t("id-doc")}/{t("driving-license")}/{t("passport")}
                          </p>
                          {/* <p className="font-satoshi standard_verify_content-flex">
                            <img src={require("../assets/facial_icon.png")} />
                            {t("face-recog")}
                          </p> */}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-5">
                      {/* FAQ */}
                      <div>
                        <div className="container">
                          <div
                            class="accordion accordion-flush"
                            id="accordionFlushExample"
                          >
                            <div className="faq mt-5">
                              <h5 className="faq-title">FAQ</h5>
                              <div class="accordion-item font-satoshi">
                                <h2
                                  class="accordion-header"
                                  id="flush-headingOne"
                                >
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseOne"
                                  >
                                    1. {t("what-is-kyb")}
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseOne"
                                  class="accordion-collapse collapse"
                                  aria-labelledby="flush-headingOne"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div class="accordion-body">
                                    {t("kyb-stands-for-know-your-business")}
                                  </div>
                                </div>
                              </div>
                              <div class="accordion-item font-satoshi">
                                <h2
                                  class="accordion-header"
                                  id="flush-headingTwo"
                                >
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseTwo"
                                  >
                                    2. {t("why-kyb-important")}
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseTwo"
                                  class="accordion-collapse collapse"
                                  aria-labelledby="flush-headingTwo"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div class="accordion-body">
                                    {t("kyb-is-crucial-for-ensuring")}
                                  </div>
                                </div>
                              </div>
                              <div class="accordion-item font-satoshi">
                                <h2
                                  class="accordion-header"
                                  id="flush-headingThree"
                                >
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseThree"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseThree"
                                  >
                                    3. {t("what-information-is-typically")}
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseThree"
                                  class="accordion-collapse collapse"
                                  aria-labelledby="flush-headingThree"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div class="accordion-body">
                                    {t("kyb-usually-requires-information")}
                                  </div>
                                </div>
                              </div>
                              {/* four */}
                              <div class="accordion-item font-satoshi">
                                <h2
                                  class="accordion-header"
                                  id="flush-headingFour"
                                >
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFour"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseFour"
                                  >
                                    4. {t("how-often-should-kyb")}
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseFour"
                                  class="accordion-collapse collapse"
                                  aria-labelledby="flush-headingFour"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div class="accordion-body">
                                    {t("kyb-checks-should-be-performed")}
                                  </div>
                                </div>
                              </div>
                              {/* five */}
                              <div class="accordion-item font-satoshi">
                                <h2
                                  class="accordion-header"
                                  id="flush-headingFive"
                                >
                                  <button
                                    class="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFive"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseFive"
                                  >
                                    5. {t("what-are-the-consequences-of-not")}
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseFive"
                                  class="accordion-collapse collapse"
                                  aria-labelledby="flush-headingFive"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div class="accordion-body">
                                    {t("failing-to-perform-kyb-can-result")}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Kyb;
